import React, { useState } from 'react';

export default function BankDetails() {
  const [accountHolderName, setAccountHolderName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [routingNumber, setRoutingNumber] = useState('');
  const [bankName, setBankName] = useState('');
  const [bankAddress, setBankAddress] = useState('');
  const [accountType, setAccountType] = useState('');
  const [currencyType, setCurrencyType] = useState('');
  const [bankCountry, setBankCountry] = useState('');
  const [contactInfo, setContactInfo] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    let obj = {
      accountHolderName: accountHolderName,
      accountNumber: accountNumber,
      routingNumber: routingNumber,
      bankName: bankName,
      bankAddress: bankAddress,
      accountType: accountType,
      currencyType: currencyType,
      bankCountry: bankCountry,
      contactInfo: contactInfo
    }
    console.log(obj);
    // Handle form submission here
  }

  return (
    <div className="container-fluid">
      <div className="col-xl-12 col-md-12 mb-4">
        <div className="row">
          <div className="card card-cls ebook-card">
            <div className="card-body create__sessionCard ">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="accountHolderName" className="session__label">Account Holder Name:</label>
                  <input type="text" className="form-control" id="accountHolderName" value={accountHolderName} onChange={(e) => setAccountHolderName(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="accountNumber" className="session__label">Account Number:</label>
                  <input type="text" className="form-control" id="accountNumber" value={accountNumber} onChange={(e) => setAccountNumber(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="routingNumber" className="session__label">IFSC Code:</label>
                  <input type="text" className="form-control" id="routingNumber" value={routingNumber} onChange={(e) => setRoutingNumber(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="bankName" className="session__label">Bank Name:</label>
                  <input type="text" className="form-control" id="bankName" value={bankName} onChange={(e) => setBankName(e.target.value)} />
                </div>
                {/* <div className="form-group">
                          <label htmlFor="bankAddress" className="session__label">Bank Address:</label>
                          <input type="text" className="form-control" id="bankAddress" value={bankAddress} onChange={(e) => setBankAddress(e.target.value)} />
                        </div> */}
                <div className='row'>
                  <div className="form-group col">
                    <label htmlFor="accountType" className="session__label">Account Type:</label>
                    <input type="text" className="form-control" id="accountType" value={accountType} onChange={(e) => setAccountType(e.target.value)} />
                  </div>
                  <div className="form-group col">
                    <label htmlFor="currencyType" className="session__label">Currency Type:</label>
                    <input type="text" className="form-control" id="currencyType" value={currencyType} onChange={(e) => setCurrencyType(e.target.value)} />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="bankCountry" className="session__label">Bank Country:</label>
                  <input type="text" className="form-control" id="bankCountry" value={bankCountry} onChange={(e) => setBankCountry(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="contactInfo" className="session__label">Contact Info:</label>
                  <input type="text" className="form-control" id="contactInfo" value={contactInfo} onChange={(e) => setContactInfo(e.target.value)} />
                </div>

                <input className='btn btn-danger' type="submit" value="Submit" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
