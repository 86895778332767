import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { propTypes } from 'react-bootstrap/esm/Image';

const changeHandler = (e, props) => {
  let value = null;
  // console.log('before',e);
  if (e?.value !== undefined) value = e.value;
  else if (e?.length > 0) value = e;
  // console.log('after',e);
  props.onChangeFunc(e);

  if (!props.onValidateFunc) return;

  let msg = null;
  if (!value && props.isReq) {
    msg = `Please select ${props.title}.`;
  }

  props.onValidateFunc(msg, props.name);
};

const Select = (props) => {
  const inputProps = {
    name: props.name,
    placeholder: props.placeholder || `Select ${props.title}`,
    className: props.className,
    isClearable: props.isClearable,
    value: props.value,
    options: props.options,
    label: props.label,
    IsMulti: props.IsMulti,
    components: props.components,
    closeMenuOnSelect: props.closeMenuOnSelect,
    hideSelectedOptions: props.hideSelectedOptions,
    allowSelectAll: props.allowSelectAll,
    styles: {
      ...props.styles,
      control: (provided, state) => ({
        ...provided,
        textAlign: 'left', // Align placeholder text to the left
      }),
    },
    inputData:props.inputData,
    menuPlacement:props.menuPlacement
  };

  return (
    <div className={props.outerClassName}>
      
      <label className="form-label " htmlFor="label">
        {props.label}
      </label>
      {
        props.isCreatable
        ? <CreatableSelect
            
            isMulti={props.IsMulti}
            menuPlacement={props.menuPlacement}
            {...inputProps}
            onChange={(e) =>{
              console.log(e);
              if(props.is2d){
                e=e[0];
              }
              // console.log('input data', props.inputData)
              let bool=0;
              if(props?.inputData){
                // console.log('e: ',e);
                // console.log("data: ",props.inputData);
                const data=props.inputData;
                data.forEach((item)=>{
                  if(item.value===e.value){
                    // console.log('returned 0');
                    bool=1;
                   
                    return;
                  }
                })
              }
              // console.log('e here',e);
              if(bool){
                console.log('value not print');
                // return;
                // props.onValidateFunc("You already select that ", e.value);
              }else{
                changeHandler(e, props)
              }
            }  
            } />
        : <ReactSelect
            isMulti={props.IsMulti}
            menuPlacement={props.menuPlacement}
            {...inputProps}
            onChange={(e) =>{

               changeHandler(e, props)
              
            }} />
      }
      {props.errorMsg && (
        <span className="text-danger">
          {props.errorMsg === true
            ? `Please select ${props.title}.`
            : props.errorMsg}
        </span>
      )}
      
    </div>
  );
};

Select.defaultProps = {
  name: '',
  title: '',
  placeholder: '',
  className: '',
  outerClassName: 'mb-2',
  isClearable: true,
  value: '',
  inputData:'',
  label: "",
  IsMulti: false,
  options: [],
  onChangeFunc: () => { },
  isReq: null,
  onValidateFunc: () => { },
  components: "",
  closeMenuOnSelect: false,
  hideSelectedOptions: false,
  allowSelectAll: false,
  styles: "",
  is2d:false,
  menuPlacement:"bottom"
};

Select.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  outerClassName: PropTypes.string,
  IsMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  value: PropTypes.any,
  options: PropTypes.array,
  onChangeFunc: PropTypes.func,
  isReq: PropTypes.bool,
  errorMsg: PropTypes.any,
  label: PropTypes.string,
  onValidateFunc: PropTypes.func,
  components: PropTypes.any,
  closeMenuOnSelect: PropTypes.bool,
  hideSelectedOptions: PropTypes.bool,
  allowSelectAll: PropTypes.bool,
  styles: PropTypes.any,
  inputData:propTypes.any,
  is2d:PropTypes.bool,
  menuPlacement:PropTypes.string
};

export default memo(Select);


// menuPlacement="top"