import {
  createMentorConstants,
  createMentorSessionServiceConstants,
  findMentorConstants,
  mentorActiveSubscriptionConstants,
  mentorEbooksConstants,
  mentorProductsConstants,
  mentorSessionServiceConstants,
  mentorSubscriptionRequestsConstants,
  mentorTotalSubscriptionConstants,
  openSessionsConstants,
  topMentorsConstants,
  publishEbookConstants,
  viewMentorProfileConstants,
  availableDayConstants,
  availableTimeConstants,
  mentorAvailableHoursConstants,
  getMentorAvailableHoursConstants,
  getAllMentorSessionConstants,
  addNotesConstants,
  getNotesConstants,
  updateNotesConstants,
  getUpcomingTaskConstants,
  getUpcomingMeetingConstants,
  getHistoryMeetingConstants,
  createMeetingConstants,
  createTaskConstants,
  updateTaskConstants,
  cancelMeetingConstants,
  createProductConstants,
  shareOnLinkedInConstants,
} from "../../constants";

export const findMentorReducer = (state = {}, action) => {
  switch (action.type) {
    case findMentorConstants.FIND_MENTOR_REQUEST:
      console.log("i am called")
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case findMentorConstants.FIND_MENTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: action.error,
      };
    case findMentorConstants.FIND_MENTOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const createMentorReducer = (state = {}, action) => {
  switch (action.type) {
    case createMentorConstants.CREATE_MENTOR_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case createMentorConstants.CREATE_MENTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: action.error,
      };
    case createMentorConstants.CREATE_MENTOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const viewMentorProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case viewMentorProfileConstants.VIEW_MENTOR_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        request: action.data,
      };
    case viewMentorProfileConstants.VIEW_MENTOR_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: action.error,
      };
    case viewMentorProfileConstants.VIEW_MENTOR_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const mentorServiceSessionTypeReducer = (state = {}, action) => {
  switch (action.type) {
    case mentorSessionServiceConstants.SESSION_TYPE_REQUEST:
      return {
        ...state,
        data: action.data,
      };
    default:
      return state;
  }
};

export const mentorSessionServiceDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case mentorSessionServiceConstants.SESSION_DETAILS_REQUEST:
      return {
        ...state,
        data: action.data,
      };
    default:
      return state;
  }
};

export const mentorSessionServiceAvailabilityReducer = (state = {}, action) => {
  switch (action.type) {
    case mentorSessionServiceConstants.SESSION_AVAILABILITY_REQUEST:
      return {
        ...state,
        data: action.data,
      };
    default:
      return state;
  }
};

export const mentorSessionServicesReducer = (state = {}, action) => {
  switch (action.type) {
    case mentorSessionServiceConstants.SESSION_SERVICES_REQUEST:
      return {
        ...state,
        data: action.data,
      };
    default:
      return state;
  }
};

export const mentorSessionServiceHelpMenteeReducer = (state = {}, action) => {
  switch (action.type) {
    case mentorSessionServiceConstants.SESSION_HELP_MENTEE_REQUEST:
      return {
        ...state,
        data: action.data,
      };
    default:
      return state;
  }
};

export const mentorSessionServiceMileStoneReducer = (state = {}, action) => {
  switch (action.type) {
    case mentorSessionServiceConstants.SESSION_MILESTONE_REQUEST:
      return {
        ...state,
        data: action.data,
      };
    default:
      return state;
  }
};
export const mentorSessionServiceFormDataReducer = (state = {}, action) => {
  switch (action.type) {
    case mentorSessionServiceConstants.SESSION_SERVICE_FORM_DATA_REQUEST:
      return {
        ...state,
        data: action.data,
      };
    default:
      return state;
  }
};

export const createMentorSessionServiceReducer = (state = {}, action) => {
  switch (action.type) {
    case createMentorSessionServiceConstants.CREATE_MENTOR_SESSION_SERVICE_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case createMentorSessionServiceConstants.CREATE_MENTOR_SESSION_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: action.error,
      };
    case createMentorSessionServiceConstants.CREATE_MENTOR_SESSION_SERVICE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const publishEbookReducer = (state = {}, action) => {
  switch (action.type) {
    case publishEbookConstants.PUBLISH_EBOOK_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case publishEbookConstants.PUBLISH_EBOOK_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: action.error,
        success: true,
      };
    case publishEbookConstants.PUBLISH_EBOOK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        success: false,
      };
    default:
      return state;
  }
};

export const mentorProductsReducer = (state = {}, action) => {
  switch (action.type) {
    case mentorProductsConstants.MENTOR_PRODUCTS_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case mentorProductsConstants.MENTOR_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: action.error,
      };
    case mentorProductsConstants.MENTOR_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const mentorEbooksReducer = (state = {}, action) => {
  switch (action.type) {
    case mentorEbooksConstants.MENTOR_EBOOKS_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case mentorEbooksConstants.MENTOR_EBOOKS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: action.error,
      };
    case mentorEbooksConstants.MENTOR_EBOOKS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export const mentorTotalSubscriptionReducer = (state = {}, action) => {
  switch (action.type) {
    case mentorTotalSubscriptionConstants.MENTOR_TOTAL_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case mentorTotalSubscriptionConstants.MENTOR_TOTAL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: action.error,
      };
    case mentorTotalSubscriptionConstants.MENTOR_TOTAL_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const openSessionsReducer = (state = {}, action) => {
  switch (action.type) {
    case openSessionsConstants.OPEN_SESSIONS_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case openSessionsConstants.OPEN_SESSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: action.error,
      };
    case openSessionsConstants.OPEN_SESSIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export const topMentorsReducer = (state = {}, action) => {
  switch (action.type) {
    case topMentorsConstants.TOP_MENTOR_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case topMentorsConstants.TOP_MENTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: action.error,
      };
    case topMentorsConstants.TOP_MENTOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export const mentorActiveSubscriptionReducer = (state = {}, action) => {
  switch (action.type) {
    case mentorActiveSubscriptionConstants.MENTOR_ACTIVE_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case mentorActiveSubscriptionConstants.MENTOR_ACTIVE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: action.error,
      };
    case mentorActiveSubscriptionConstants.MENTOR_ACTIVE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export const mentorSubscriptionRequestsReducer = (state = {}, action) => {
  switch (action.type) {
    case mentorSubscriptionRequestsConstants.MENTOR_SUBSCRIPTION_REQUESTS_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case mentorSubscriptionRequestsConstants.MENTOR_SUBSCRIPTION_REQUESTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: action.error,
      };
    case mentorSubscriptionRequestsConstants.MENTOR_SUBSCRIPTION_REQUESTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export const mentorAvailabilityDaysReducer = (state = {}, action) => {
  switch (action.type) {
    case availableDayConstants.AVAILABLE_DAY_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case availableDayConstants.AVAILABLE_DAY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: action.error,
      };
    case availableDayConstants.AVAILABLE_DAY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export const mentorAvailabilityTimeSlotsReducer = (state = {}, action) => {
  switch (action.type) {
    case availableTimeConstants.AVAILABLE_TIME_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case availableTimeConstants.AVAILABLE_TIME_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: action.error,
      };
    case availableTimeConstants.AVAILABLE_TIME_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const mentorAvailableHoursReducer = (state = {}, action) => {
  switch (action.type) {
    case mentorAvailableHoursConstants.MENTOR_AVAILABLE_HOURS_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
        isSuccess: false,
      };
    case mentorAvailableHoursConstants.MENTOR_AVAILABLE_HOURS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        isSuccess: true,
        error: action.error,
      };
    case mentorAvailableHoursConstants.MENTOR_AVAILABLE_HOURS_FAILURE:
      return {
        ...state,
        loading: false,
        isSuccess: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export const getMentorAvailableHoursReducer = (state = {}, action) => {
  switch (action.type) {
    case getMentorAvailableHoursConstants.GET_MENTOR_AVAILABLE_HOURS_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case getMentorAvailableHoursConstants.GET_MENTOR_AVAILABLE_HOURS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: action.error,
      };
    case getMentorAvailableHoursConstants.GET_MENTOR_AVAILABLE_HOURS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const getAllMentorSessionReducer = (state = {}, action) => {
  switch (action.type) {
    case getAllMentorSessionConstants.GET_ALL_MENTOR_SESSION_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case getAllMentorSessionConstants.GET_ALL_MENTOR_SESSION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: action.error,
      };
    case getAllMentorSessionConstants.GET_ALL_MENTOR_SESSION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export const addNotesReducer = (state = {}, action) => {
  switch (action.type) {
    case addNotesConstants.ADD_NOTES_REQUEST:
      return {
        ...state,
        request: action.data,
        isSuccess: false,
        loading: true,
      };
    case addNotesConstants.ADD_NOTES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        isSuccess: true,
        status: action.status,
        error: action.error,
      };
    case addNotesConstants.ADD_NOTES_FAILURE:
      return {
        ...state,
        loading: false,
        isSuccess: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export const getNotesReducer = (state = {}, action) => {
  switch (action.type) {
    case getNotesConstants.GET_NOTES_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case getNotesConstants.GET_NOTES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: action.error,
      };
    case getNotesConstants.GET_NOTES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export const updateNotesReducer = (state = {}, action) => {
  switch (action.type) {
    case updateNotesConstants.UPDATE_NOTES_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case updateNotesConstants.UPDATE_NOTES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: action.error,
      };
    case updateNotesConstants.UPDATE_NOTES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const getUpcomingTaskReducer = (state = {}, action) => {
  switch (action.type) {
    case getUpcomingTaskConstants.UPCOMING_TASK_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case getUpcomingTaskConstants.UPCOMING_TASK_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: action.error,
      };
    case getUpcomingTaskConstants.UPCOMING_TASK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const getUpcomingMeetingReducer = (state = {}, action) => {
  switch (action.type) {
    case getUpcomingMeetingConstants.UPCOMING_MEETING_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case getUpcomingMeetingConstants.UPCOMING_MEETING_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: action.error,
      };
    case getUpcomingMeetingConstants.UPCOMING_MEETING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export const getHistoryMeetingReducer = (state = {}, action) => {
  switch (action.type) {
    case getHistoryMeetingConstants.HISTORY_MEETING_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case getHistoryMeetingConstants.HISTORY_MEETING_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: action.error,
      };
    case getHistoryMeetingConstants.HISTORY_MEETING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export const createMeetingReducer = (state = {}, action) => {
  switch (action.type) {
    case createMeetingConstants.CREATE_MEETING_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
        isSuccess: false,
      };
    case createMeetingConstants.CREATE_MEETING_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        isSuccess: true,
        status: action.status,
        error: action.error,
      };
    case createMeetingConstants.CREATE_MEETING_FAILURE:
      return {
        ...state,
        loading: false,
        isSuccess: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export const createTaskReducer = (state = {}, action) => {
  switch (action.type) {
    case createTaskConstants.CREATE_TASK_REQUEST:
      return {
        ...state,
        request: action.data,
        isSuccess: false,
        loading: true,
      };
    case createTaskConstants.CREATE_TASK_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        isSuccess: true,
        status: action.status,
        error: action.error,
      };
    case createTaskConstants.CREATE_TASK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        isSuccess: false,
      };
    default:
      return state;
  }
};
export const updateTaskReducer = (state = {}, action) => {
  switch (action.type) {
    case updateTaskConstants.UPDATE_TASK_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case updateTaskConstants.UPDATE_TASK_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: action.error,
      };
    case updateTaskConstants.UPDATE_TASK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export const cancelMeetingReducer = (state = {}, action) => {
  switch (action.type) {
    case cancelMeetingConstants.CANCEL_MEETING_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case cancelMeetingConstants.CANCEL_MEETING_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: action.error,
      };
    case cancelMeetingConstants.CANCEL_MEETING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export const createProductReducer = (state = {}, action) => {
  switch (action.type) {
    case createProductConstants.CREATE_PRODUCTS_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case createProductConstants.CREATE_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: action.error,
      };
    case createProductConstants.CREATE_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export const shareOnLinkedInReducer = (state = {}, action) => {
  console.log(action)
  switch (action.type) {
    case shareOnLinkedInConstants.SHARE_ON_LINKEDIN_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true,
      };
    case shareOnLinkedInConstants.SHARE_ON_LINKEDIN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        isSuccess: true,
        status: action.status,
      };
    case shareOnLinkedInConstants.SHARE_ON_LINKEDIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        isSuccess: false,
        status: action.status,
      };
    default:
      return state;
  }
};
