import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import fbLogo from "../../assets/images/fb.png";
import twitterLogo from "../../assets/images/twitter.png";
import linkedInLogo from "../../assets/images/linkedin.png";
import instaLogo from "../../assets/images/insta.png";
import youtubeLogo from "../../assets/images/youtube.png";
import LoginModal from "../modals/loginModal";
import SignUpModal from "../modals/signUpModal";
import { Link } from "react-router-dom";
// import Footer from "../../footer";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FeedbackForm } from "../Feedback";
import { subscribeAction } from "../../redux/actions/common";
// import { emailSubscribeForBlogUpdate } from "../../redux/actions/common";

const Footer = () => {
  const navigate = useNavigate();

  const [headerModal, setHeaderModal] = useState({});
  const [feedbackModal, setFeedbackModal] = useState({});
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const subscriberesponce = useSelector((state) => state.subscribeReducer);

  console.log("respons", subscriberesponce);

  useEffect(() => {
    if (subscriberesponce?.error !== undefined && loading === false) {
      setSuccessMessage("You already subscribed to our newsletter!");
    }
    if (subscriberesponce?.status === 200 && loading === false) {
      setSuccessMessage("subscribed Sucessfully!");
    }
  }, [subscriberesponce, loading]);

  const submitemail = async () => {
    setErrorMessage("");
    setLoading(validateEmail(email));

    if (email === "") {
      setErrorMessage("Enter email address");
      setLoading(false);
    } else if (validateEmail(email)) {
      setErrorMessage("");

      // Simulate a loading delay of 1 seconds
      await new Promise((resolve) => setTimeout(resolve, 1000));

      const data = { email: email };
      try {
        await dispatch(subscribeAction(data)); // Make the API call only when email is valid
      } finally {
        setLoading(false);
      }
    } else {
      setErrorMessage("Enter a valid email address");
      setLoading(false);
    }
  };

  const handleEmailChange = (event) => {
    const inputEmail = event.target.value;
    setEmail(inputEmail);
    setErrorMessage("");
    setSuccessMessage("");
  };

  const validateEmail = (inputEmail) => {
    // Basic email validation pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(inputEmail);
  };

  return (
    <>
      <footer style={{position:"relative",top:"60px"}}>
        <div className="why_choose_ment">
          <div className="container">
            <div className="row">
              <div className="col-6 col-sm-6 col-md-3">
                <div className="met_fot1">
                  <h4>MentorPal</h4>
                  <p>Best mentorship and guidance under one roof.</p>
                  <h6>Follow us:</h6>
                  <div className="foot_ican">
                    <ul>
                      <li>
                        <a href="https://www.facebook.com/profile.php?id=100090574942977" target="_blank" rel="noreferrer">
                          <img alt="" src={fbLogo} />
                        </a>
                      </li>
                      <li>
                        <a href="https://twitter.com/mentorpal" target="_blank" rel="noreferrer">
                          <img alt="" src={twitterLogo} />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com/company/mentorpal" target="_blank" rel="noreferrer">
                          <img alt="" src={linkedInLogo} />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/mentorpalai" target="_blank" rel="noreferrer">
                          <img alt="" src={instaLogo} />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.youtube.com/@mentorpal" target="_blank" rel="noreferrer">
                          <img alt="" src={youtubeLogo} />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="blog_subs_f">
                  <h6 className="sub_blog_h">Subscribe For Blog Updates</h6>
                  <li className="blog_li_item">
                    <input
                      type="email"
                      placeholder="Email*"
                      id="email_forblog"
                      className="email_blog_ol blog_email"
                      style={{ width: "100%", padding: "8px" }}
                      onChange={handleEmailChange}
                    />
                    <button
                      type="submit"
                      className="email_blog_ol blog_email_signup"
                      onClick={submitemail}
                      disabled={loading} // Disable button while loading
                    >
                      {/* SUBSCRIBE */} {loading ? "Loading..." : "SUBSCRIBE"}
                    </button>
                    {errorMessage && (
                      <p className="error_message">{errorMessage}</p>
                    )}
                    {successMessage && (
                      <p className="success_message">{successMessage}</p>
                    )}
                  </li>
                </div>
              </div>
              <div className="col-6 col-sm-6 col-md-2">
                <div className="met_fot1">
                  <h5 className="footer-title">Services</h5>
                  <div className="foot_cont">
                    <ul className="footer-menu">
                      <li>
                        <a href="/find-mentor">Explore Mentors</a>
                      </li>
                      <li>
                        <a href="/open-sessions">Live Session</a>
                      </li>
                      <li>
                        <a
                          className=" footer-menu li a " // href='#'
                          onClick={() => navigate("/become-mentor")}
                        >
                          Become a mentor
                        </a>
                      </li>
                      {/* <li>
                      <a href="">Group Mentorship</a>
                    </li> */}
                    </ul>
                  </div>
                </div>
                <div className="met_fot1 display_change_a">
                  <h5 className="footer-title">Courses Offered</h5>
                  <div className="foot_cont">
                    <ul className="footer-menu">
                    
                      <li>
                        <a href="/find-mentor/content-writing">Content Writing</a>
                      </li>
                      <li>
                        <a href="/find-mentor/data-science">Data Science</a>
                      </li>
                      <li>
                        <a href="/find-mentor/design">Design</a>
                      </li>
                      <li>
                        <a href="/find-mentor/marketing">Marketing</a>
                      </li>
                      <li>
                        <a href="/find-mentor/product-management">Product Management</a>
                      </li>
                      <li>
                        <a href="/find-mentor/product-research">Product Research</a>
                      </li>
                      <li>
                        <a href="/find-mentor/software-development">Software Development</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-6 col-sm-6 col-md-3">
                <div className="met_fot1 met_fot2">
                  <h6 className="footer-title">Get in Touch</h6>
                  <div className="foot_cont">
                    <p>
                      #D1004,Goyal Orchid Whitefield
                      <br /> Bengaluru,Karnataka (560066)
                      <br /> India
                    </p>
                  </div>
                  <div className="foot_cont">
                    <a href="mailto:help@mentorpal.ai">help@mentorpal.ai</a>
                    <a href="tel:9660367722">+91 8619825569 </a>
                  </div>
                </div>
              </div>
              <div className="col-6 col-sm-6 col-md-2">
                <div className="met_fot1">
                  <h5 className="footer-title">About MentorPal</h5>
                  <div className="foot_cont">
                    <ul className="footer-menu">
                      <li>
                        <a href="/about-us">About Us</a>
                      </li>
                      <li>
                        <a href="/privacy-policy">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="/terms">Terms & Conditions</a>
                      </li>
                      <li>
                        <a href="/limited-use-disclosure">
                          Limited Use Disclosure
                        </a>
                      </li>
                      <li>
                        <a href="/contact-us">Contact Us</a>
                      </li>
                      <li>
                        <a
                          data-toggle="modal"
                          data-target="#loginForm"
                          onClick={() =>
                            setFeedbackModal({
                              modal: FeedbackForm,
                              show: true,
                            })
                          }
                        >
                          Feedback Us
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-6 col-sm-6 col-md-2 display_change_b">
                <div className="met_fot1">
                  <h5 className="footer-title">Courses Offered</h5>
                  <div className="foot_cont">
                    <ul className="footer-menu">
                    
                      <li>
                        <a href="/find-mentor/content-writing">Content Writing</a>
                      </li>
                      <li>
                        <a href="/find-mentor/data-science">Data Science</a>
                      </li>
                      <li>
                        <a href="/find-mentor/design">Design</a>
                      </li>
                      <li>
                        <a href="/find-mentor/marketing">Marketing</a>
                      </li>
                      <li>
                        <a href="/find-mentor/product-management">Product Management</a>
                      </li>
                      <li>
                        <a href="/find-mentor/product-research">Product Research</a>
                      </li>
                      <li>
                        <a href="/find-mentor/software-development">Software Development</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <section className="copyright why_choose_ment">
        <div className="container">
          <div className="copyright-cont text-center">
            <p>Copyright © MPAL Solutions Private Limited.</p>
          </div>
        </div>
      </section>
      {headerModal?.modal && (
        <headerModal.modal
          headerModal={headerModal}
          setHeaderModal={setHeaderModal}
        />
      )}
      {feedbackModal?.modal && (
        <feedbackModal.modal
          feedbackModal={feedbackModal}
          setFeedbackModal={setFeedbackModal}
        />
      )}
      {/* <Footer/> */}
    </>
  );
};

export default Footer;
