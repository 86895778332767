import { call, put } from "redux-saga/effects";
import {
  createMentorConstants,
  createMentorSessionServiceConstants,
  findMentorConstants,
  mentorActiveSubscriptionConstants,
  mentorEbooksConstants,
  mentorProductsConstants,
  mentorSubscriptionRequestsConstants,
  mentorTotalSubscriptionConstants,
  publishEbookConstants,
  viewMentorProfileConstants,
  openSessionsConstants,
  topMentorsConstants,
  availableDayConstants,
  availableTimeConstants,
  mentorAvailableHoursConstants,
  getMentorAvailableHoursConstants,
  getAllMentorSessionConstants,
  addNotesConstants,
  getNotesConstants,
  updateNotesConstants,
  getUpcomingTaskConstants,
  getUpcomingMeetingConstants,
  getHistoryMeetingConstants,
  createMeetingConstants,
  createTaskConstants,
  updateTaskConstants,
  cancelMeetingConstants,
  createProductConstants,
  shareOnLinkedInConstants,
} from "../../constants";
import {
  createMentor,
  findMentor,
  viewMentorProfile,
  createMentorSessionServiceCall,
  publishEbookCall,
  mentorProductsCall,
  mentorEbooksCall,
  mentorTotalSubscriptionCall,
  openSessionsCall,
  topMentorsCall,
  mentorActiveSubscriptionCall,
  mentorSubscriptionRequestsCall,
  getMentorAvailabilityDays,
  getMentorAvailabilityTimeSlots,
  mentorAvailableHoursCall,
  getMentorAvailableHoursCall,
  getAllMentorSessionCall,
  addNotesCall,
  getNotesCall,
  updateNotesCall,
  getUpcomingTaskCall,
  getUpcomingMeetingCall,
  getHistoryMeetingCall,
  createTaskCall,
  createMeetingCall,
  updateTaskCall,
  cancelMeetingCall,
  createProductCall,
  linkedinShare,
} from "../../network";

export function* findMentorSaga(action) {
  try {
    const response = yield call(findMentor, action.data);
    yield put({
      type: findMentorConstants.FIND_MENTOR_SUCCESS,
      headers: response.headers,
      data: response.data,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: findMentorConstants.FIND_MENTOR_FAILURE,
      error: e.message,
    });
  }
}

export function* createMentorSaga(action) {
  try {
    const response = yield call(createMentor, action.data);
    yield put({
      type: createMentorConstants.CREATE_MENTOR_SUCCESS,
      headers: response.headers,
      data: response.data,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: createMentorConstants.CREATE_MENTOR_FAILURE,
      error: e.message,
    });
  }
}

export function* viewMentorProfileSaga(action) {
  try {
    const response = yield call(viewMentorProfile, action.data);
    yield put({
      type: viewMentorProfileConstants.VIEW_MENTOR_PROFILE_SUCCESS,
      headers: response.headers,
      data: response.data,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: viewMentorProfileConstants.VIEW_MENTOR_PROFILE_FAILURE,
      error: e.message,
    });
  }
}

export function* createMentorSessionServiceSaga(action) {
  try {
    const response = yield call(createMentorSessionServiceCall, action.data);
    yield put({
      type: createMentorSessionServiceConstants.CREATE_MENTOR_SESSION_SERVICE_SUCCESS,
      headers: response.headers,
      data: response.data,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: createMentorSessionServiceConstants.CREATE_MENTOR_SESSION_SERVICE_FAILURE,
      error: e.message,
    });
  }
}

export function* publishEbookSaga(action) {
  try {
    const response = yield call(publishEbookCall, action.data);
    yield put({
      type: publishEbookConstants.PUBLISH_EBOOK_SUCCESS,
      headers: response.headers,
      data: response.data,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: publishEbookConstants.PUBLISH_EBOOK_FAILURE,
      error: e.message,
    });
  }
}

export function* mentorProductsSaga(action) {
  try {
    const response = yield call(mentorProductsCall, action.data);
    yield put({
      type: mentorProductsConstants.MENTOR_PRODUCTS_SUCCESS,
      headers: response.headers,
      data: response.data,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: mentorProductsConstants.MENTOR_PRODUCTS_FAILURE,
      error: e.message,
    });
  }
}

export function* mentorEbooksSaga(action) {
  try {
    const response = yield call(mentorEbooksCall, action.data);
    yield put({
      type: mentorEbooksConstants.MENTOR_EBOOKS_SUCCESS,
      headers: response.headers,
      data: response.data,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: mentorEbooksConstants.MENTOR_EBOOKS_FAILURE,
      error: e.message,
    });
  }
}
export function* mentorTotalSubscriptionSaga(action) {
  try {
    const response = yield call(mentorTotalSubscriptionCall, action.data);
    yield put({
      type: mentorTotalSubscriptionConstants.MENTOR_TOTAL_SUBSCRIPTION_SUCCESS,
      headers: response.headers,
      data: response.data,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: mentorTotalSubscriptionConstants.MENTOR_TOTAL_SUBSCRIPTION_FAILURE,
      error: e.message,
    });
  }
}
export function* openSessionsSaga(action) {
  try {
    const response = yield call(openSessionsCall, action.data);
    yield put({
      type: openSessionsConstants.OPEN_SESSIONS_SUCCESS,
      headers: response.headers,
      data: response.data,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: openSessionsConstants.OPEN_SESSIONS_FAILURE,
      error: e.message,
    });
  }
}
export function* getTopMentorSaga(action) {
  try {
    const response = yield call(topMentorsCall);
    yield put({
      type: topMentorsConstants.TOP_MENTOR_SUCCESS,
      headers: response.headers,
      data: response.data,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: topMentorsConstants.TOP_MENTOR_FAILURE,
      error: e.message,
    });
  }
}
export function* mentorActiveSubscriptionSaga(action) {
  try {
    const response = yield call(mentorActiveSubscriptionCall, action.data);
    yield put({
      type: mentorActiveSubscriptionConstants.MENTOR_ACTIVE_SUBSCRIPTION_SUCCESS,
      headers: response.headers,
      data: response.data,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: mentorActiveSubscriptionConstants.MENTOR_ACTIVE_SUBSCRIPTION_FAILURE,
      error: e.message,
    });
  }
}
export function* mentorSubscriptionRequestsSaga(action) {
  try {
    const response = yield call(mentorSubscriptionRequestsCall, action.data);
    yield put({
      type: mentorSubscriptionRequestsConstants.MENTOR_SUBSCRIPTION_REQUESTS_SUCCESS,
      headers: response.headers,
      data: response.data,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: mentorSubscriptionRequestsConstants.MENTOR_SUBSCRIPTION_REQUESTS_FAILURE,
      error: e.message,
    });
  }
}
export function* mentorAvailabilityDaysSaga(action) {
  try {
    const response = yield call(getMentorAvailabilityDays, action.data);
    yield put({
      type: availableDayConstants.AVAILABLE_DAY_SUCCESS,
      headers: response.headers,
      data: response.data,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: availableDayConstants.AVAILABLE_DAY_FAILURE,
      error: e.message,
    });
  }
}
export function* mentorAvailabilityTimeSlotsSaga(action) {
  try {
    const response = yield call(getMentorAvailabilityTimeSlots, action.data);
    yield put({
      type: availableTimeConstants.AVAILABLE_TIME_SUCCESS,
      headers: response.headers,
      data: response.data,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: availableTimeConstants.AVAILABLE_TIME_FAILURE,
      error: e.message,
    });
  }
}

export function* mentorAvailableHoursSaga(action) {
  try {
    const response = yield call(mentorAvailableHoursCall, action.data);
    yield put({
      type: mentorAvailableHoursConstants.MENTOR_AVAILABLE_HOURS_SUCCESS,
      headers: response.headers,
      data: response.data,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: mentorAvailableHoursConstants.MENTOR_AVAILABLE_HOURS_FAILURE,
      error: e.message,
    });
  }
}
export function* getMentorAvailableHoursSaga(action) {
  try {
    const response = yield call(getMentorAvailableHoursCall, action.data);
    yield put({
      type: getMentorAvailableHoursConstants.GET_MENTOR_AVAILABLE_HOURS_SUCCESS,
      headers: response.headers,
      data: response.data,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: getMentorAvailableHoursConstants.GET_MENTOR_AVAILABLE_HOURS_FAILURE,
      error: e.message,
    });
  }
}

export function* getAllMentorSessionSaga(action) {
  try {
    const response = yield call(getAllMentorSessionCall, action.data);
    yield put({
      type: getAllMentorSessionConstants.GET_ALL_MENTOR_SESSION_SUCCESS,
      headers: response.headers,
      data: response.data,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: getAllMentorSessionConstants.GET_ALL_MENTOR_SESSION_FAILURE,
      error: e.message,
    });
  }
}
export function* addNotesSaga(action) {
  try {
    const response = yield call(addNotesCall, action.data);
    yield put({
      type: addNotesConstants.ADD_NOTES_SUCCESS,
      headers: response.headers,
      data: response.data,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: addNotesConstants.ADD_NOTES_FAILURE,
      error: e.message,
    });
  }
}
export function* getNotesSaga(action) {
  try {
    const response = yield call(getNotesCall, action.data);
    yield put({
      type: getNotesConstants.GET_NOTES_SUCCESS,
      headers: response.headers,
      data: response.data,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: getNotesConstants.GET_NOTES_FAILURE,
      error: e.message,
    });
  }
}
export function* updateNotesSaga(action) {
  try {
    const response = yield call(updateNotesCall, action.data);
    yield put({
      type: updateNotesConstants.UPDATE_NOTES_SUCCESS,
      headers: response.headers,
      data: response.data,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: updateNotesConstants.UPDATE_NOTES_FAILURE,
      error: e.message,
    });
  }
}

export function* getUpcomingTaskSaga(action) {
  try {
    const response = yield call(getUpcomingTaskCall, action.data);
    yield put({
      type: getUpcomingTaskConstants.UPCOMING_TASK_SUCCESS,
      headers: response.headers,
      data: response.data,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: getUpcomingTaskConstants.UPCOMING_TASK_FAILURE,
      error: e.message,
    });
  }
}

export function* getUpcomingMeetingSaga(action) {
  try {
    const response = yield call(getUpcomingMeetingCall, action.data);
    yield put({
      type: getUpcomingMeetingConstants.UPCOMING_MEETING_SUCCESS,
      headers: response.headers,
      data: response.data,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: getUpcomingMeetingConstants.UPCOMING_MEETING_FAILURE,
      error: e.message,
    });
  }
}
export function* getHistoryMeetingSaga(action) {
  try {
    const response = yield call(getHistoryMeetingCall, action.data);
    yield put({
      type: getHistoryMeetingConstants.HISTORY_MEETING_SUCCESS,
      headers: response.headers,
      data: response.data,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: getHistoryMeetingConstants.HISTORY_MEETING_FAILURE,
      error: e.message,
    });
  }
}
export function* createMeetingSaga(action) {
  try {
    const response = yield call(createMeetingCall, action.data);
    yield put({
      type: createMeetingConstants.CREATE_MEETING_SUCCESS,
      headers: response.headers,
      data: response.data,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: createMeetingConstants.CREATE_MEETING_FAILURE,
      error: e.message,
    });
  }
}
export function* createTaskSaga(action) {
  try {
    const response = yield call(createTaskCall, action.data);
    yield put({
      type: createTaskConstants.CREATE_TASK_SUCCESS,
      headers: response.headers,
      data: response.data,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: createTaskConstants.CREATE_TASK_FAILURE,
      error: e.message,
    });
  }
}
export function* updateTaskSaga(action) {
  try {
    const response = yield call(updateTaskCall, action.data);
    yield put({
      type: updateTaskConstants.UPDATE_TASK_SUCCESS,
      headers: response.headers,
      data: response.data,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: updateTaskConstants.UPDATE_TASK_FAILURE,
      error: e.message,
    });
  }
}
export function* cancelMeetingSaga(action) {
  try {
    const response = yield call(cancelMeetingCall, action.data);
    yield put({
      type: cancelMeetingConstants.CANCEL_MEETING_SUCCESS,
      headers: response.headers,
      data: response.data,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: cancelMeetingConstants.CANCEL_MEETING_FAILURE,
      error: e.message,
    });
  }
}
export function* createProdcutSaga(action) {
  try {
    const response = yield call(createProductCall, action.data);
    yield put({
      type: createProductConstants.CREATE_PRODUCTS_SUCCESS,
      headers: response.headers,
      data: response.data,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: createProductConstants.CREATE_PRODUCTS_FAILURE,
      error: e.message,
    });
  }
}

export function* shareOnLinkedInSaga(action) {
  try {
    const response = yield call(linkedinShare, action.data);
    console.log(response)
    yield put({
      type: shareOnLinkedInConstants.SHARE_ON_LINKEDIN_SUCCESS,
      data: response.data,
    });
  } catch (e) {
    yield put({
      type: shareOnLinkedInConstants.SHARE_ON_LINKEDIN_FAILURE,
      error: e.message,
    });
  }
}