import React, { useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserDetails } from "../../redux/actions/login";
import {
  mentorActiveSubscription,
  mentorSubscriptionRequests,
  mentorTotalSubscription,
} from "../../redux/actions/mentor";
import { REACT_USER_ID } from "../auth/tokenProvider";

const AdminDashboardSection = () => {
  const user_id = REACT_USER_ID;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {

    import("./css/custom.css");
  }, []);

  useEffect(() => {
    dispatch(getUserDetails(user_id));
    dispatch(mentorTotalSubscription(user_id));
    dispatch(mentorSubscriptionRequests(user_id));
    dispatch(mentorActiveSubscription("a75dc1e4-85d3-4de2-92b0-4e9bf90b67b0"));
  }, []);
  const mentorSubscriptionRequestsReducerData = useSelector(
    (state) => state.mentorSubscriptionRequestsReducer
  );
  const mentorSubscriptionRequestsList =
    mentorSubscriptionRequestsReducerData?.data || [];

  const userDetailsReducerData = useSelector((state) => {
    return state.userDetailsReducer;
  });
  const mentorSubscriptionsReducerData = useSelector(
    (state) => state.mentorActiveSubscriptionReducer
  );
  const mentorActiveSubscriptionsList =
    mentorSubscriptionsReducerData?.data || [];
  const mentorTotalSubscriptionData = useSelector(
    (state) => state.mentorTotalSubscriptionReducer?.data
  );
  console.log("mentorTotalSubscriptionData", mentorTotalSubscriptionData);
  const userDetailsArray = userDetailsReducerData?.data || [];
  const userDetails = userDetailsArray[0];

  const isLoading = userDetailsReducerData?.loading;

  return (
    <div className="container-fluid">
      {/* <!-- Page Heading --> */}
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        {/* <h1 className='h3 mb-0 text-gray-800 main-title'>Dashboard</h1> */}
      </div>
      {
      isLoading ? (
        <div className="loader">
          <TailSpin ariaLabel="Loading..." color="#00BFFF" />
        </div>
      ) : (
        <div className="row">
          <div className="col-md-6 mb-3">
            <div className="card db-bg">
              <h4>Hi, {userDetails?.name}</h4>
              <h6>Hi, {userDetails?.name}</h6>
              <div className="progress-bar">
                <div className="inner-progress">
                  <div className="active-progress"></div>
                </div>
              </div>
              <p>Be more discoverable, add more information</p>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => navigate(userDetails?.mentor_intro_video? "profile":"upload-intro")}
              >
             { userDetails?.mentor_intro_video?  "View Profile" : "Upload intro video"}
              </button>
            </div>
          </div>

          {/* <div className="col-md-6 mb-3">
            <div className="card db-wbg">
              <h4>Total Subcription</h4>
              <h3>
                {mentorTotalSubscriptionData?.total_subcription}{" "}
                <span>Subscription</span>
              </h3>
              <div className="Subscription-btn">
                <button type="button" className="btn btn-danger">
                  {mentorTotalSubscriptionData?.active_subscription} Active
                  Subscription
                </button>
                <button
                  type="button"
                  className="btn btn-solid"
                  onClick={() => navigate("mentor-subscriptions")}
                >
                  View All Subcriptions
                </button>
              </div>
            </div>
          </div> */}
          
        </div>
      )}

      {/* <!-- Content Row --> */}
      <div className="row">
        <div className="col-xl-12 col-md-12 mb-4">
          <div className="card">
            <div className="card-header header-table">
              <div className="card-left">
                <h5>New Mentorship Request(s)</h5>
              </div>
              <div className="card-right">
                <a href="#" onClick={() => navigate("all-mentorship-requests")}>
                  View all
                </a>
              </div>
            </div>
            {mentorSubscriptionRequestsList.length > 0 ? (
              <div className="card-body table-inner db-table">
                <div className="table-responsive">
                  <table style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Session Type</th>
                        <th>Date</th>
                        <th>Slot</th>
                        <th>Payment</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {mentorSubscriptionRequestsList
                        .slice(0, 2)
                        .map((request) => (
                          <tr>
                            <td>{request?.mentee_id}</td>
                            <td>{request?.product_details?.title}</td>
                            <td>7 February</td>
                            <td>8 PM to 9 PM</td>
                            <td className="unpaid">Unpaid</td>
                            <td btn-bar>
                              <button className="btn active-btn">Accept</button>
                              <button className="btn reject-btn">Reject</button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div>
                <div
                  style={{
                    "text-align": "center",
                    "padding-bottom": "1rem",
                  }}
                >
                  <h2>Request not found</h2>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-6 col-md-6 mb-4">
          <div className="card">
            <div className="card-header header-table">
              <div className="card-left">
                <h5>Active Mentoship</h5>
              </div>
              <div className="card-right">
                <a href="#" onClick={() => navigate("mentor-subscriptions")}>
                  View all
                </a>
              </div>
            </div>

            <div className="card-body">
              {mentorActiveSubscriptionsList.length > 0 ? (
                mentorActiveSubscriptionsList.map((subscription) => (
                  <div className="d-flex border-cls">
                    <span className="pro-bg">RB</span>
                    <h3 className="pro-name">
                      Reena baantu
                      <span className="small-cls">reena_bantu@gmail.com</span>
                    </h3>
                    <span className="ml-auto">Just Now</span>
                  </div>
                ))
              ) : (
                <div>
                  <div
                    style={{ "text-align": "center", "padding-bottom": "1rem" }}
                  >
                    <h2>No Active Subscription</h2>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-md-6 mb-4">
          <div className="card">
            <div className="card-header header-table">
              <div className="card-left">
                <h5>Unpaid Earnings</h5>
              </div>
              <div className="card-right">
                <a href="#">View Details</a>
              </div>
            </div>
            <div className="card-body">
              <div className="unpaid-earning">
                {/* <div className="left-side-earning">
                  <p>
                    $500 <span>Updated yesterday</span>
                  <p/>
                </div> */}
                <div className="right-side-earning">
                  <p>
                    Payments are calculated at the end of every month for
                    mentors who meet their minimum payout amount.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12 col-md-12 mb-4">
          <div className="card">
            <div className="card-header header-table">
              <div className="card-left">
                <h5>Unpaid Earnings</h5>
              </div>
              <div className="card-right">
                <a href="#">View Details</a>
              </div>
            </div>
            <div className="card-body">
              <div className="sesssion-earning">
                <div className="left-side-earning">
                  <h1>
                    ${mentorTotalSubscriptionData?.unpaid_earnings}{" "}
                    <span>
                      {mentorTotalSubscriptionData?.session_done} Sessions Done
                    </span>
                  </h1>
                </div>
                {/* <div className="right-side-earning">
                  <h5>Joined on 2 Feb'22</h5>
                  <h4>You Helped 100 Mentees</h4>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardSection;
