import React from "react";
import { UploadImage } from "../../../UploadImage";
import { updateProfile } from "../../../../redux/actions/login";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import CamDashboard from "../../../VIdeoRecorder/CamDashboard";
import "../../../../components/UploadImage/UploadImage.css";
import { TailSpin } from "react-loader-spinner";
const Seven = ({
  registrationProcessData,
  setRegistrationProcessData,
  errors,
  onStartCapture,
}) => {
  const dispatch = useDispatch();
  const USER_ID = localStorage.getItem("REACT_USER_ID");
  const [videoShow, setvideoShow] = useState(true);
  const [reordModel, setRecordModel] = useState({});
  const [data, setData] = useState({});
  const [loader, setloader] = useState({});
  const [error, seterror] = useState({});

  const uploadImageFileReducer = useSelector((state) => state.uploadImageFileReducer);
  useEffect(() => {
    setloader(uploadImageFileReducer?.loading);
    setData(uploadImageFileReducer?.data);
    seterror(uploadImageFileReducer?.error);
    console.log("uploadImageFileReducer", uploadImageFileReducer);
    console.log(data);
  }, [uploadImageFileReducer, loader, data]);


  const handleImage = (val) => {
    if (val) {
      dispatch(updateProfile({ mentor_intro_video: val, user_id: USER_ID }));
      setvideoShow(false);
      if (!errors?.mentor_intro_video?.onValidateFunc) return;
      let msg = null;
      if (!val && errors?.mentor_intro_video?.isReq) {
        msg = `Please select Intro Video .`;
      }
      errors?.mentor_intro_video?.onValidateFunc(msg, "mentor_intro_video");
    }
  };
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      if (progress < 100) {
        setProgress((prevProgress) => prevProgress + 1);
      } else {
        clearInterval(timer);
      }
    }, loader); // Adjust the interval to control the speed of progress
    return () => {
      clearInterval(timer);
    };
  }, [progress, loader]);
  const loaderStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const progressCountStyle = {
    fontSize: '24px',
    color: '#4fa94d',
    marginTop: '10px',
  };
  return (
    <>
      {
        loader
          ? (
            <div className="loader open_sessions_loader" style={loaderStyle}>
              <TailSpin height={80} width={80} visible={true} ariaLabel="Loading..." color="#00BFFF" />
              <div className="progress-count" style={progressCountStyle}>{progress}%</div>
            </div>
          ) : (null)}

      <div className="container2">
        <div className="registrationCard vdo-upload-registration">
          <div className=" leftSide">
            <h3 className="mb-32 fw-bold video_heading">
              Let Us Get to Know You through Video
            </h3>

            <p className="related-context" style={{ whiteSpace: "normal" }}>
              If you have a introduction video you can upload it now, if you
              don't have already we would recommend you to make one.
            </p>

            <div className=" joIaGT d-flex">
              <p
                className=" hAXIJe ml-2 grey-1-text"
                style={{ whiteSpace: "normal" }}
              >
                Your privacy is important to us, and we will never use your
                introduction video without your permission. By uploading your
                video, you give us permission to share it on our app/website and
                social media channels. You can revoke this permission at any
                time by contacting us directly.
              </p>
            </div>
          </div>

          <div className="row leftSide">
            {videoShow && (
              <div style={{ textAlign: "end" }}>
                <a className="btn" href="/">
                  Skip
                </a>
              </div>
            )}
            <div className="container">
              <UploadImage
                callback={handleImage}
                fileName="_mentor_intro_video"
                imagePath={"mentorIntroVideo/"}
                filetype={["video"]}
              />

              {/* Reocod a video was giving error too many render. so temp removed */}
              <div className="get-started-cls">
                <button
                  className="btn find-btn btn-mentor-apply"
                  data-toggle="modal"
                  data-target="#loginForm"
                  onClick={() => {
                    setRecordModel({ modal: CamDashboard, show: true });
                  }}
                >
                  Record a Video
                </button>
              </div>

              <div className="get-started-cls">
                {!videoShow && (
                  <a href="/">
                    <button
                      type="button"
                      className="find-btn btn btn-mentor-apply "
                    >
                      Done
                    </button>
                  </a>
                )}
              </div>
            </div>
          </div>
          {/* </div> */}
          {/* </div> */}
          {/* </div> */}
          {errors?.intro_video?.errorMsg && (
            <span className="text-danger">
              {errors?.intro_video?.errorMsg === true
                ? `Please select Intro Video .`
                : errors?.intro_video?.errorMsg}
            </span>
          )}
          {reordModel?.modal && (
            <reordModel.modal
              reordModel={reordModel}
              setRecordModel={setRecordModel}
            />
          )}
        </div>
      </div>
     </>
  );
};

export default Seven;
