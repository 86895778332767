import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, Link, useLocation } from "react-router-dom";
import menuIcon from "../../assets/images/menu.png";
import crossIcon from "../../assets/images/cross-icon.png";
import SignUpModal from "../modals/signUpModal";
import logo from "../../assets/images/logo-w.png";
import "../dashboard/css/media.css";
import "../header/navbar.css";
import {
  findMentorAction
} from "../../redux/actions/mentor";

const Header = ({ extraClasses = "", isVdoBannerPage }) => {
  // console.log('vso',isVdoBannerPage);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const setBackground = (location.pathname === "/" || location.pathname === "/pricing")?true:false;
  console.log(setBackground)
  const [isMentorsLoading, setIsMentorsLoading] = useState(false);
  const [search, setSearch] = useState("java");
  const [headerModal, setHeaderModal] = useState({});
  const [isOpen, setIsOpen] = useState(true);
  const expertise = [
    { label: "Content Writing", value: "content-writing" },
    { label: "Data Science", value: "data-science" },
    { label: "Design", value: "design" },
    { label: "Marketing", value: "marketing" },
    { label: "Product Management", value: "product-management" },
    { label: "Product Research", value: "product-research" },
    { label: "Software Development", value: "software-development" },
  ];
  const service = [
    { label: "Mentorship", value: "https://mentorpal.ai/mentorship" },
    { label: "Jobs", value: "https://ats.mentorpal.ai/" },
   
  ];

  let MENTOR_PAGE_SIZE = 12,
    MENTOR_PAGE_NUMBER = 1;
  const findMentors = () => {
    const data = {
      pageSize: MENTOR_PAGE_SIZE,
      pageNo: MENTOR_PAGE_NUMBER,
      skills: search,
    };
    dispatch(findMentorAction(data));
    navigate("find-mentor");
  };

  const clickPopular = (value) => {
    const data = {
      pageSize: MENTOR_PAGE_SIZE,
      pageNo: MENTOR_PAGE_NUMBER,
      experties: value,
    };
    dispatch(findMentorAction(data));
    if (window.location.pathname !== "/find-mentor") {
      navigate("../find-mentor", {
        state: {
          expertise: value
        }
      });
    }
    else {
      navigate("/find-mentor", {
        state: {
          expertise: value
        }
      });
    }
  };
  const [showPopup, setShowPopup] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  useEffect(() => {
    if (!isButtonClicked) {
      const id = setTimeout(() => {
        const isLoggedIn = false;
        if (!isLoggedIn && !isVdoBannerPage) {
          setHeaderModal({
            modal: SignUpModal,
            show: true,
            as: "mentee",
          });
        }
      }, 20000);
      setTimeoutId(id);
    }
  }, [isButtonClicked]);

  return (
    <header className={`${extraClasses} header-cls`}  >
      <meta
        name="viewport"
        content="width=device-width, initial scale=1.0,user-scalable=no"
      />
      <div
        className="container"
        style={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <nav
          className="menu navbar navbar-expand-lg navbar__fix"
          style={{ display: "", flexBasis: "" }}
        >
          <a className="navbar-brand" href="/">
            <img src={logo} alt="logo" className="header-logo ms-auto" />
          </a>
          <button
            className={"navbar-toggler"}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#headerHandler"
            aria-controls="headerHandler"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <img
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              alt=""
              src={isOpen ? menuIcon : crossIcon}
            />
          </button>
          <div
            className="menu collapse navbar-collapse rounded Mobile_menu m-2"
            style={{ display: "", flexBasis: "" }}
            id="headerHandler"
          >
            <ul className="navbar-nav align-items-center Mobile_menu_list">
              {/* <li id={(isVdoBannerPage === true) ? "styleNavBackground" : ""}
                className=" nav-item dropdown "
              >
                <a
                  className="nav-link header_nav_item dropdown-toggle"
                  href="#"
                  id="navbarDropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Find Mentor
                </a>
                <div
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  {expertise?.map((item, idx) => (
                    <Link key={idx} to={`/find-mentor/${item?.value}`} className="pl-2 dropdown-item" >
                      <div className="header_find_mentor_dropdown">
                        {item?.label}
                      </div>
                    </Link>

                  ))}
                  
                </div>
              </li> */}
              <li id={(isVdoBannerPage === true) ? "styleNavBackground" : ""}
                className=" nav-item dropdown "
              >
                <a
                  className="nav-link header_nav_item dropdown-toggle"
                  href="#"
                  id="navbarDropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Service
                </a>
                <div
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  {service?.map((item, idx) => (
                    <a key={idx} href={item?.value} target="_blank" rel="noopener noreferrer" className="pl-2 dropdown-item">
                      <div className="header_find_mentor_dropdown">
                        {item?.label}
                      </div>
                    </a>
                  ))}
                </div>

              </li>
              {/* personality tab */}
              {/* <li className="nav-item dropdown">
                <a
                  className="nav-link header_nav_item dropdown-toggle"
                  href="#"
                  id="navbarDropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Personality
                </a>
                <div
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <a
                    className="pl-2 dropdown-item nav-link"
                    href="/personality-test"
                  >
                  Personlaity Test
                  </a>
                  <a
                    className="pl-2 dropdown-item nav-link "
                    href="/personality-types"
                  >
                  Personlaity Types
                  </a>
                  
                </div>
              </li> */}
              <li
                className="nav-item"
                id={(isVdoBannerPage === true) ? "styleNavBackground" : ""}
              >
                <Link to="/pricing" className="nav-link header_nav_item"> Pricing</Link>
                
              </li>
              <li
                className="nav-item"
                id={(isVdoBannerPage === true) ? "styleNavBackground" : ""}
              >
                
                <a
                  className="nav-link header_nav_item"
                  target={"_blank"}
                  href="/blog/"
                >
                  Blogs
                </a>
              </li>
              {/* <li
                className="nav-item "
                id={(isVdoBannerPage === true) ? "styleNavBackground" : ""}
              >
                <a className="nav-link header_nav_item " href="/master-courses">
                  Course
                </a>
              </li> */}
              <li
                className="nav-item"
                id={(isVdoBannerPage === true) ? "styleNavBackground" : ""}
              >
                <Link to="/become-mentor" className="nav-link header_nav_item">Become a Mentor</Link>
                {/* <a className="nav-link header_nav_item" href="/become-mentor">
                  Become a Mentor
                </a> */}
              </li>

              {/* <li
                className="nav-item"
                id={(isVdoBannerPage === true) ? "styleNavBackground" : ""}
              >
                <a className="nav-link header_nav_item" href="/webinar">
                  Webinar
                </a>
              </li> */}
              {/* <li
                className="nav-item"
                id={(isVdoBannerPage===true)?"styleNavBackground":""}
              >
                <a
                  className="nav-link header_nav_item"
                  href="#"
                  data-toggle="modal"
                  data-target="#loginForm"
                  onClick={() => {
                    setIsButtonClicked(true);
                    clearTimeout(timeoutId);
                    setHeaderModal({ modal: LoginModal, show: true });
                  }}
                >
                  Login
                </a>
              </li> */}
              <li className="nav-item">
                <a
                  className="button btn get-started"
                  // href='#'
                  data-toggle="modal"
                  data-target="#form"
                  onClick={() => {
                    setIsButtonClicked(true);
                    clearTimeout(timeoutId);
                    setHeaderModal({
                      modal: SignUpModal,
                      show: true,
                      as: "mentee",
                    });
                  }}
                >
                  login
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      {/* <SignupModal isOpen={headerModal} setHeaderModal={setHeaderModal} /> */}
      {headerModal?.modal && (
        <headerModal.modal
          headerModal={headerModal}
          setHeaderModal={setHeaderModal}
        />
      )}
    </header>
  );
};

export default Header;
