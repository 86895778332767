import React, { useState } from "react";
import { useEffect } from "react";
import crossIcon from "../../../assets/images/cross-icon.png";
import menuIcon from "../../../assets/images/menu.png";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../assets/images/logo-w.png";
import { REACT_USER_ID } from "../../auth/tokenProvider";
import {
  getNotificationAction,
  updateNotificationStatusAction,
} from "../../../redux/actions/common";

import { useDispatch, useSelector } from "react-redux";
import Notification from "../../Notification";
import NotificationIcon from "../../Notification/NotificationIcon";

const DashboardHeader = ({ showSideNavbar }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    import("../css/custom.css");
  }, []);
  const [isOpen, setIsOpen] = useState(true);
  const handleShowSideNavbar = () => {
    // Call the showSideNavbar function passed from AdminDashboard
    showSideNavbar();
  };
  useEffect(() => {
    if (REACT_USER_ID) {
      console.log("working");
      dispatch(getNotificationAction(REACT_USER_ID));
    }
  }, []);

  const myNotificationData = useSelector(
    (state) => state.getNotificationReducer?.data
  );

  return (
    <>
      <header className="inner-header header-cls" style={{ height: "59px" }}>
        <meta
          name="viewport"
          content="width=device-width, initial scale=1.0,user-scalable=no"
        />
        <div className="container main___navbar">
          <nav className="menu navbar navbar-expand-lg">
            <a className="navbar-brand" href="/">
              <img src={logo} style={{ width: 200 }} />
            </a>
            <div className="mobile___notification">

              {/* <NotificationIcon /> */}
            </div>
            <button
              className={"navbar-toggler"}
              type="button"
              onClick={handleShowSideNavbar}
            >
              <img
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
                alt=""
                src={isOpen ? menuIcon : menuIcon}
              />{" "}
            </button>
            <div
              className="menu collapse navbar-collapse rounded ms-auto"
              id="headerHandler"
            >
              {/* <!-- Topbar Navbar --> */}
              <ul className="navbar-nav ml-auto ">
                <div className="mentor-desktop-nav">
                  <ul class="navbar-nav ml-auto">
                    <li className="nav-item dropdown">
                      {/* <NotificationIcon /> */}
                    </li>
                  </ul>
                  {/* <!-- Nav Item - Messages --> */}
                  {/* <li className="nav-item dropdown no-arrow mx-1">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        id="messagesDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img src={notifImg} />
                      </a>
                      <div
                        className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                        aria-labelledby="messagesDropdown"
                      >
                        <h6 className="dropdown-header">Message Center</h6>
                        <a
                          className="dropdown-item d-flex align-items-center"
                          href="#"
                        >
                          <div className="dropdown-list-image mr-3">
                            <img
                              className="rounded-circle"
                              src={undrawProfileImg1}
                              alt="..."
                            />
                            <div className="status-indicator bg-success"></div>
                          </div>
                          <div className="font-weight-bold">
                            <div className="text-truncate">
                              Hi there! I am wondering if you can help me with a
                              problem I've been having.
                            </div>
                            <div className="small text-gray-500">
                              Emily Fowler · 58m
                            </div>
                          </div>
                        </a>
                        <a
                          className="dropdown-item d-flex align-items-center"
                          href="#"
                        >
                          <div className="dropdown-list-image mr-3">
                            <img
                              className="rounded-circle"
                              src={undrawProfileImg2}
                              alt="..."
                            />
                            <div className="status-indicator"></div>
                          </div>
                          <div>
                            <div className="text-truncate">
                              I have the photos that you ordered last month, how
                              would you like them sent to you?
                            </div>
                            <div className="small text-gray-500">
                              Jae Chun · 1d
                            </div>
                          </div>
                        </a>
                        <a
                          className="dropdown-item d-flex align-items-center"
                          href="#"
                        >
                          <div className="dropdown-list-image mr-3">
                            <img
                              className="rounded-circle"
                              src={undrawProfileImg3}
                              alt="..."
                            />
                            <div className="status-indicator bg-warning"></div>
                          </div>
                          <div>
                            <div className="text-truncate">
                              Last month's report looks great, I am very happy
                              with the progress so far, keep up the good work!
                            </div>
                            <div className="small text-gray-500">
                              Morgan Alvarez · 2d
                            </div>
                          </div>
                        </a>
                        <a
                          className="dropdown-item d-flex align-items-center"
                          href="#"
                        >
                          <div className="dropdown-list-image mr-3">
                            <img
                              className="rounded-circle"
                              src="https://source.unsplash.com/Mv9hjnEUHR4/60x60"
                              alt="..."
                            />
                            <div className="status-indicator bg-success"></div>
                          </div>
                          <div>
                            <div className="text-truncate">
                              Am I a good boy? The reason I ask is because
                              someone told me that people say this to all dogs,
                              even if they aren't good...
                            </div>
                            <div className="small text-gray-500">
                              Chicken the Dog · 2w
                            </div>
                          </div>
                        </a>
                        <a
                          className="dropdown-item text-center small text-gray-500"
                          href="#"
                        >
                          Read More Messages
                        </a>
                      </div>
                    </li>
                    <div className="topbar-divider d-none d-sm-block"></div>

                    <li className="nav-item dropdown no-arrow">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        id="userDropdown"
                        role="button"
                        data-toggle="dropdown-toggle"
                        aria-controls="alertsDropdownMenu"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          className="img-profile rounded-circle"
                          src={undrawProfileImg}
                        />
                        <h5 className="mr-2 d-none d-lg-inline"></h5>
                      </a>
                      <div
                        className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                        aria-labelledby="userDropdown"
                      >
                        <Link className="dropdown-item" to="profile">
                          <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                          Profile
                        </Link>
                        <Link className="dropdown-item" to="#">
                          <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                          Settings
                        </Link>
                        <a className="dropdown-item" href="#">
                          <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                          Activity Log
                        </a>
                        <div className="dropdown-divider"></div>
                        <Link
                          className="dropdown-item"
                          data-toggle="modal"
                          data-target="#logoutModal"
                          onClick={handleLogOut}
                        >
                          <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                          Logout
                        </Link>
                      </div>
                    </li> */}
                  {/* </ul> */}
                </div>
              </ul>
              {/* </nav> */}
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};

export default DashboardHeader;
