import React from 'react'
import { useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import { REACT_USER_ID } from "../../auth/tokenProvider";
import { useDispatch, useSelector } from "react-redux";
import { getNotificationAction, updateNotificationStatusAction } from "../../../redux/actions/common";
import './notifications.css'
const Notifications = () => {
  const dispatch = useDispatch();
  const markread = () => {
    console.log("All marks read api hit");

    dispatch(updateNotificationStatusAction());
  };
  useEffect(() => {
    if (REACT_USER_ID) {
      console.log('working');
      dispatch(getNotificationAction(REACT_USER_ID))
    }
  }, [])

  const NotificationLoading = useSelector((state) => state.getNotificationReducer?.loading);

  const myNotificationData = useSelector((state) => state.getNotificationReducer?.data);

  function formatTime(datetimeStr) {
    const options = { hour: 'numeric', minute: '2-digit', hour12: true };
    const dateOptions = { day: 'numeric', month: 'long' };

    const datetime = new Date(datetimeStr);
    const time = datetime.toLocaleTimeString('en-US', options);
    const date = datetime.toLocaleDateString('en-US', dateOptions);

    return `${time}, ${date}`;
  }
  return (
    <div className='notificatons'>
      <div className='notifications_wrapper'>
        <div className='all_notification_heading'>
          <h1>Notifications</h1>
          <div className='all_mark_read'>
            <button onClick={markread}>Mark All as Read</button>
          </div>

        </div>
        <div className='all_notifications'>
          {NotificationLoading ? (
            <div className="justify-content-center d-flex mt-5">
              <TailSpin ariaLabel="Loading..." color="#00BFFF" />
            </div>
          ) : myNotificationData && Object.keys(myNotificationData).length > 0 ? (
            myNotificationData.map((notificationData, key) => (
              <div className='notification' key={key}>
                <img width='32px' height='32px' src='' className='notification_image' />
                <span className='notification_content'>{notificationData.data.message}</span>
                <span className='all_notification_time'>
                  {formatTime(notificationData.created_at)}
                </span>
              </div>

            ))

          ) :
            (
              <>
                <div className="no-notification">
                  <div className="text-center">
                    <h3>
                      No Notifications
                    </h3>
                  </div>
                </div>
              </>
            )}


        </div>
      </div>
    </div>
  )
}

export default Notifications;