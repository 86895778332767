import React, { useEffect, useRef, useState } from "react";
import {
    bannerImg
} from "../../../assets/images/export-images";
import hireTalentLogo from "./img/hireTalentLogo2.svg";
import hireTalentLogo2 from "./img/hireTalentLogo.png";
import Testimonial from "../../Testimonial/testimonial";
import fbLogo from "../../../assets/images/fb.png";
import twitterLogo from "../../../assets/images/twitter.png";
import linkedInLogo from "../../../assets/images/linkedin.png";
import instaLogo from "../../../assets/images/insta.png";
import youtubeLogo from "../../../assets/images/youtube.png";
import { hireTaletnFeatures } from "../../MasterCourses/data";
import "../../../assets/css/main-banner.css";
import "../cutsom.css";
import { Form, Input, Checkbox, Button, message } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { fullStackAction } from "../../../redux/actions/common";
import { HireTalentSeo } from "../../Seo/data";
import SeoHelmet from "../../Seo/Helmet/SeoHelmet";
import img1 from '../img/working_4727424.png';
import img2 from '../img/7339268.png';
import img3 from '../img/6171902.png';
import img5 from '../img/4153553.jpg';
import { motion } from 'framer-motion';
import ProtectedHeader from "../../ProtectedHeader/ProtectedHeader";
import Header from "../../header";
const HireTalentHomePage = () => {

  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("REACT_TOKEN_AUTH") ? true : false
  );
  const point__svg = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#d96980"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
    </svg>
  );
  const faq = [
    {
      question:
        "What sets Mentorpal apart in tech hiring?",
      answer:
        "Mentorpal stands out by conducting a comprehensive pre-interview process, ensuring only the most qualified candidates proceed. This not only enhances your hiring efficiency but also integrates seamlessly with your existing workflow, adding significant value."
    },
    {
      question:
        "What's the structure of your technical interviews?", answer:
        "Our technical interviews are meticulously designed to assess both fundamental and advanced skills, tailored to match the specific requirements of each role. We ensure alignment with your needs by closely collaborating with your hiring team."
    },
    {
      question:
        "What qualifications do your interviewers hold?",
      answer:
        "Our interviewers are seasoned professionals from top-tier tech companies, boasting extensive experience in various technical domains. We meticulously match interviewer expertise with candidate profiles to ensure a deep and relevant evaluation.",
    },
    {
      question:
        " How do you ensure candidate quality and fit?",
      answer: "We evaluate candidates for both technical proficiency and cultural compatibility, ensuring they align with your company's values and team dynamics. Should a recommended candidate not meet your final expectations, we're committed to reassessing and refining our selection process.",
    },
    {
      question: "How does Mentorpal handle client feedback?",
      answer: "Client feedback is integral to our process. We employ a structured feedback loop to continually refine our candidate selection and interview methodologies, ensuring constant improvement and alignment with your needs.",
    },
    {
      question: " What's your pricing structure and ROI?",
      answer: "We offer transparent pricing tailored to your specific needs, ensuring clear ROI by significantly enhancing your hiring efficiency and quality. No long-term commitments are required to start; we believe in proving our value through results."
    },
    {
      question: " How does your service integrate with existing HR processes?",
      answer: "Mentorpal is designed to complement and enhance your current HR systems, offering flexible integration and dedicated support to ensure smooth operations and effective communication throughout the hiring cycle."
    },
    {
      question: " Do you have any success stories or case studies?",
      answer: "Yes, we have numerous success stories where we've dramatically streamlined the tech hiring process for our clients, leading to quicker hires and higher quality candidates. We're happy to share these upon request."
    },
    {
      question: " Can your service scale with our hiring needs?",
      answer: "Absolutely. Mentorpal is built for scalability, capable of accommodating everything from individual hires to large-scale recruitment drives, with an average turnaround time that sets industry standards."
    },
    {
      question: "How do you ensure legal compliance and data privacy?",
      answer: "We adhere strictly to legal and compliance standards, ensuring all operations respect data privacy and confidentiality laws. We're committed to maintaining the highest levels of integrity and security in all our processes."
    },
  ];
  const targetDivRef = useRef(null);
  const handleButtonClick = () => {
    if (targetDivRef.current) {
      console.log('Scrolling to the div:', targetDivRef.current);
      try {
        targetDivRef.current.scrollIntoView({ behavior: 'smooth' });
      } catch (error) {
        console.error('Error while scrolling:', error);
      }
    } else {
      console.warn('Target div ref is not assigned.');
    }
  };
  return (
    <>
      {/* Header */}
      <SeoHelmet metaData={HireTalentSeo} />

      {loggedIn ? <ProtectedHeader /> : <Header />}

      {/* hero section */}
      <section
        className="banner-hiretalent"
        style={{
          backgroundImage: `url(${bannerImg})`,
          padding: "0 0px 85px 0px !important",
        }}

      />
      <div
        className="hero-section app-hero"
      // style={{
      //   backgroundImage:
      //     "url(https://www.bhmpics.com/downloads/light-purple-background/43.360_f_449663060_l2k0iovxxfiqgqwkosw2ctfnxz7iksfc.jpg)",
      // }}
      >
        <div className="container">
          <div className="hero-content app-hero-content text-center">
            <div className="row justify-content-md-center">
              <div className="col-md-12">
                <h1 style={{ color: "#11294f" }}>
                  Fast track your tech hiring with prescreened candidates
                </h1>
                <p
                  className=""
                  style={{
                    fontSize: "1.5rem",
                    lineHeight: "2.5rem",
                    color: "#11294f",
                  }}
                >
                  We provide potential hires post deep vetting tech interviews,{" "}
                  <br />
                  custom tailored to your company growth.
                </p>
                <br className="d-block d-md-none" />{" "}
                <div className=" get-started-cls">
                  <button type="button" className="find-btn btn btn-primary">
                    <a
                      href="#form"
                      style={{
                        listStyle: "none",
                        textDecoration: "none",
                        color: "white",
                      }}
                      onClick={handleButtonClick}
                    >
                      {" "}
                      Start Hiring
                    </a>
                  </button>
                </div>
                <div className="text-muted" style={{ marginTop: 10 }}>
                  we'll alleviate 80% of your screening workload.                </div>
              </div>
            </div>
          </div>
          <div className="services-section text-center" id="services">
            <div className="container">
              <div className="row justify-content-md-center">
                <div className="col-md-8" />
                <div className="col-md-12 text-center">
                  <div className="services">
                    <div className="row">
                      <div
                        className="col-sm-4 wow fadeInUp"
                        data-wow-delay="0.2s"
                      >
                        <div className="services-icon">
                          <img
                            alt="Service"
                            height={60}
                            src={img1}
                            width={60}
                          />
                        </div>
                        <div className="services-description">
                          <h1>Provide Your Job Description  </h1>
                          <p>
                            Submit the specifics of the role you're looking to fill; include skills, qualifications, and cultural fit to help us understand your exact needs.

                          </p>
                        </div>
                      </div>
                      <div
                        className="col-sm-4 wow fadeInUp"
                        data-wow-delay="0.3s"
                      >
                        <div className="services-icon">
                          <img
                            alt="Service"
                            className="icon-2"
                            height={60}
                            src={img2}
                            width={60}
                          />
                        </div>
                        <div className="services-description">
                          <h1>We conduct initial tech interview</h1>
                          <p>
                            Our experts handle the first round of technical assessments, ensuring only the most qualified candidates advance, saving you time and resources.
                          </p>
                        </div>
                      </div>
                      <div
                        className="col-sm-4 wow fadeInUp"
                        data-wow-delay="0.4s"
                      >
                        <div className="services-icon">
                          <img
                            alt="Service"
                            className="icon-3"
                            height={60}
                            src={img3}
                            width={60}
                          />
                        </div>
                        <div className="services-description">
                          <h1>Proceed with the final stages</h1>
                          <p>
                            With the preliminary screening done, you can focus on the final interviews to select the best fit for your team from the shortlisted candidates.                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        {/* First Class Experience */}
        <div className="row padding____section">
          <div className="col-sm-4 col-md-6 d-flex justify-content-center">
            <div className="benefit_wrapper">
              <div className="benefit_header">
                <h2 className="header_head">Why Mentorpal?</h2>
              </div>
              <div className="benefits_sales benefit_counter  fce_pitch">
                <div className="benefits_pitch">
                  <h3 className="text">Efficiency at Every Step </h3>
                  <p className="para">
                    We understand that the conventional hiring process can be a
                    drain on your valuable resources, particularly the time of
                    your skilled engineers. With Mentorpal, you can say goodbye
                    to the endless cycle of screening and first-round technical
                    rejections.
                  </p>
                </div>
                <div className="benefits_pitch">
                  <h3 className="text">Beyond the Resume</h3>
                  <p className="para">
                    In today's market, a good resume isn't good enough. We delve
                    deeper, conducting initial technical interviews on your
                    behalf to ensure that only the most qualified candidates
                    make it to your doorstep.
                  </p>
                </div>
                <div className="benefits_pitch">
                  <h3 className="text">A Network of Excellence</h3>
                  <p className="para">
                    Our pool of interviewers isn't just experienced; they're
                    proven experts from Tier 1 tech companies, bringing insights
                    and evaluation standards that set us apart.
                  </p>
                </div>
                <div className="benefits_pitch">
                  <h3 className="text">Tailored Matches for Every Role</h3>
                  <p className="para">
                    We understand that every role is unique. Our matching
                    algorithm and expert insights ensure candidates align with
                    your specific technical and cultural requirements. Benefit
                    from our deep industry knowledge and a personalized approach
                    to every hiring challenge.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4 col-md-6">
            <img
              className="benefit_desktop payment_img right_desktop "
              src={img5}
              alt="First Class Experience Desktop"
            />
          </div>
        </div>
      </div>
      {/* course features */}
      <section className="benefits ">

        <ProcessCards />


        {/* <div className="get-started-cls mb-5">
            <a href="https://calendly.com/mentorpalai/25min" target="_blank">
              <button type="button" className="find-btn btn btn-primary">
                Schedule a call
              </button>
            </a>
            <a
              type="button"
              className="btn btn-primary learn-more-btn"
              href="https://toptalent.mentorpal.ai/"
              target="_blank"
            >
              Learn More
            </a>
          </div> */}

      </section>

      <div className="container">
        {/* Course  coummunity*/}
        <section className="container padding____section">
          <div className="row">
            <div className="col-sm-4 col-md-6">
              <div className="benefit_header">
                <h2 className="header_head">
                  Our Process: Streamlining Your Success
                </h2>
                <div className="benefits_para two_para ben_para">
                  <div className="benefits_division">
                    <span className="tick">{point__svg}</span>
                    <p>
                      <h3 style={{ fontSize: "22px" }}>Understanding Your Needs </h3> <br />
                      We kickstart the process with an in-depth discussion to
                      understand your specific technical requirements and
                      company culture.{" "}
                    </p>
                  </div>
                  <div className="benefits_division">
                    <span className="tick">{point__svg}</span>
                    <p>
                      <h3 style={{ fontSize: "22px" }}> The Mentorpal Match </h3> <br />
                      Leveraging our vast network, we identify candidates who
                      not only meet but exceed your expectations.{" "}
                    </p>
                  </div>
                  <div className="benefits_division">
                    <span className="tick">{point__svg}</span>
                    <p>
                      <h3 style={{ fontSize: "22px" }}>Expert-Led Technical Interviews</h3>
                      <br />
                      Our tech professionals conduct thorough interviews,
                      assessing candidates on skills crucial for your role.{" "}
                    </p>
                  </div>
                  <div className="benefits_division">
                    <span className="tick">{point__svg}</span>
                    <p>
                      <h3 style={{ fontSize: "22px" }}> Shortlist of the Best</h3>
                      <br />
                      Receive a curated list of top candidates, complete with
                      detailed interview feedback and recommendations.
                    </p>
                  </div>
                  <div className="benefits_division">
                    <span className="tick">{point__svg}</span>
                    <p>
                      <h3 style={{ fontSize: "22px" }}> Seamless Integration</h3> <br />
                      Once you've made your choice, we assist with the
                      onboarding process to ensure a seamless transition.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4 col-md-6" ref={targetDivRef}>
              <EnquiryForm />

            </div>
          </div>
        </section>
      </div>
      <HowItWorks handleButtonClick={handleButtonClick} />

      {/*  testimonial section */}
      <section className=" why_choose_ment">
        <div className="container">
          <div className="container">
            <center>
              <h2 className="my-4" style={{ color: "#b7308e" }}>
                Our Inspiring Mentors & Empowered Mentees
              </h2>
            </center>
            <Testimonial />
          </div>
        </div>
      </section>

      <section
        className=" background-color-grey pt-4 "
      >
        <div className="container p-0 p-sm-5">
          <div className="frequently_asked ">
            <div className="row">
              <div className="col-md-6">
                <div className="mentoring-cont">
                  <div className="faq__pricing">
                    <h3>Frequently asked Questions</h3>
                    <div className="inner-title">
                      <h2>
                        Can't find the answer you <span> are looking for?</span>
                      </h2>
                    </div>
                  </div>
                  <ul>
                    <li>
                      <span className="tick">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24px"
                          viewBox="0 0 24 24"
                          width="24px"
                          fill="#000000"
                        >
                          <path d="M0 0h24v24H0V0z" fill="none" />
                          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
                        </svg>
                      </span>
                      <a onClick={handleButtonClick}>
                        <span className="cont-cls">Reach out to us</span>
                      </a>
                    </li>
                    <li>
                      <span className="tick">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24px"
                          viewBox="0 0 24 24"
                          width="24px"
                          fill="#000000"
                        >
                          <path d="M0 0h24v24H0V0z" fill="none" />
                          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
                        </svg>
                      </span>
                      <a>
                        <span className="cont-cls">
                          Help & grow with MentroPal
                        </span>
                      </a>
                    </li>
                    <li>
                      <span className="tick">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24px"
                          viewBox="0 0 24 24"
                          width="24px"
                          fill="#000000"
                        >
                          <path d="M0 0h24v24H0V0z" fill="none" />
                          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
                        </svg>
                      </span>
                      <span className="cont-cls">
                        Want a detail demo call
                      </span>
                    </li>
                  </ul>
                  <div className="get-started-cls" id="get-started-cls">
                    <a

                      type="button"
                      className="btn btn-primary"
                      onClick={handleButtonClick}
                    >
                      Reach out to us
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 faq___overflow">
                <div
                  class="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  {faq.map((faq, index) => (
                    <div
                      class="accordion-item"
                      style={{ marginBottom: "8px" }}
                      key={index}
                    >
                      <h2
                        class="accordion-header accordion-header_course"
                        id={`flush-heading-${index}`}
                      >
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#flush-collapse-${index}`}
                          aria-expanded="false"
                          aria-controls={`flush-collapse-${index}`}
                        >
                          {faq.question}
                        </button>
                      </h2>
                      <div
                        id={`flush-collapse-${index}`}
                        class="accordion-collapse collapse"
                        aria-labelledby={`flush-heading-${index}`}
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div class="accordion-body">{faq.answer}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

const EnquiryForm = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch()

  const onFinish = (values) => {
    console.log('Form data:', values);
    dispatch(fullStackAction(values))

  };
  const EnquiryReducer = useSelector(
    (state) => state.fullStackReducer
  );
  const [loadings, setLoadings] = useState(false);

  useEffect(() => {
    const { data, status, error, loading } = EnquiryReducer
    setLoadings(loading)
    if (data && !loading && status === 200) {
      message.success("submitted!")
      form.resetFields();
    }
    else if (!data && !loading && status !== 200) {
      console.log("something went wrong!")
    }
    else {
      console.log(error)
    }
    console.log("EnquiryReducer", EnquiryReducer)
  }, [EnquiryReducer])
  return (
    <div className=" registration-form">

      <Form
        form={form}
        onFinish={onFinish}
      
      >
        <h3 className="header_head text-center pb-3">
          Talk to our expert
        </h3>
        <Form.Item className="title-fields form-group"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please input the name!',
            },
          ]}
        >
          <Input className="form-control item" placeholder="Enter your name*" />
        </Form.Item>

        <Form.Item className="title-fields form-group"
          name="email"
          rules={[
            {
              required: true,
              message: 'Please input the Work email!',
            },
          ]}
        >
          <Input className="form-control item" placeholder="Enter your Work Email*" />
        </Form.Item>

        <Form.Item className="title-fields form-group"
          name="job_title"
          rules={[
            {
              required: true,
              message: 'Please input the company name!',
            },
          ]}
        >
          <Input className="form-control item" placeholder="Enter your Company Name*" />
        </Form.Item>

        <Form.Item className="title-fields form-group" name="mobile" initialValue="" rules={[
          {
            required: true,
            message: 'Please input mobile number!',
          },
        ]}>
          <Input className="form-control item" placeholder="Enter your phone number*" />
        </Form.Item>

        <Form.Item className="title-fields form-group"
          name="agree"
          valuePropName="checked"
          rules={[
            {
              required: true,
              message: 'Please agree to the terms!',
            },
          ]}
        >
          <Checkbox>
            I agree to the terms and conditions*
          </Checkbox>
        </Form.Item>

        <Form.Item className="title-fields form-group get-started-cls" wrapperCol={{ offset: 6, span: 14, style: { margin: 'auto' } }}>
          <Button type="primary" htmlType="submit" className="find-btn btn btn-primary" loading={loadings}>
            Get a Callback
          </Button>

        </Form.Item>
      </Form>
    </div>
  );
};
const Footer = () => {
  return (
    <>
      <footer className="course__footer">
        <div
          className="container "
          style={{ paddingLeft: "0", paddingRight: "0" }}
        >

          <div
            className="menu navbar "
            style={{ display: "", flexBasis: "" }}
          >
            <div className="col-6 col-sm-6 col-md-3">
              <div className="met_fot1" id="met_fot1">
                <img src={hireTalentLogo2} alt="mentorpalLogo" />
              </div>
            </div>



            <div className="col-6 col-sm-6 col-md-3">
              <div className="met_fot1 met_fot2">
                <h6 className="footer-title">Get in Touch</h6>
                <div className="foot_cont">
                  <p>
                    #D1004,Goyal Orchid Whitefield
                    <br /> Bengaluru,Karnataka (560066)
                    <br /> India
                  </p>
                </div>
                <div className="foot_cont">
                  <a href="mailto:help@mentorpal.ai">help@mentorpal.ai</a>
                  <a href="tel:9660367722">+91 8619825569 </a>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-3">
              <div
                className="menu  rounded Mobile_menu m-2 text-align-center"
                style={{ display: "", flexBasis: "100%" }}
                id="headerHandler"
              >
                <ul
                  className=" align-items-center  "
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: "0",
                    marginBottom: "0",
                    listStyle: "none",
                  }}
                >
                  <li className="nav-item ">
                    <div className="foot_ican course__foot_ican mx-auto">
                      <ul>
                        <li>
                          <a
                            href="https://www.facebook.com/profile.php?id=100090574942977"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img alt="" src={fbLogo} />
                          </a>
                        </li>
                        <li>
                          <a href="https://twitter.com/mentorpal">
                            <img alt="" src={twitterLogo} />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.linkedin.com/company/mentorpal/mycompany/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img alt="" src={linkedInLogo} />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.instagram.com/mentorpalai/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img alt="" src={instaLogo} />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.youtube.com/@mentorpal"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img alt="" src={youtubeLogo} />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <section className="copyright why_choose_ment">
        <div className="container">
          <div className="copyright-cont text-center">
            <p>2022 All rights are reserved @Mentorpal Company Ltd.</p>
          </div>
        </div>
      </section>
    </>
  )
}
const HireTalentHeader = () => {
  return (
    <>
      <header className={` header-cls`}>
        <meta
          name="viewport"
          content="width=device-width, initial scale=1.0,user-scalable=no"
        />
        <div
          className="container"
          style={{ paddingLeft: "0", paddingRight: "0" }}
        >
          <nav
            className="menu navbar navbar-expand-lg navbar__fix"
            style={{ display: "", flexBasis: "" }}
          >
            <a className="navbar-brand logo-container" href="/hire-talent">
              <img
                src={hireTalentLogo}
                alt="logo"
                className="header-logo ms-auto"
              />
            </a>

            <div
              className="menu collapse navbar-collapse rounded Mobile_menu m-2"
              style={{ display: "", flexBasis: "100%" }}
              id="headerHandler"
            ></div>
          </nav>
        </div>
      </header>
    </>
  )
}
const ProcessCards = () => {
  return (
    <>
      <section className="container">
        <div className="w-layout-grid grid container">
          {hireTaletnFeatures.map((milestone, index) => (
            <div key={index} className="milestone-box why-us">
              <img
                src={milestone.icon}
                loading="lazy"
                alt=""
                className="why-us-icons"
              />
              <h1 className="heading-2 general">{milestone.title}</h1>
              <div className="copy-text _2">
                {milestone.description}
              </div>
            </div>
          ))}
        </div>
      </section>


    </>
  )
}

const HowItWorks = ({ handleButtonClick }) => {
  const contact =()=>{
    handleButtonClick()
  }
  const steps = [
    {
      title: 'Job description',
      tracker: 'Job description',
      image: 'https://d2b1cooxpkirg1.cloudfront.net/publicAssets/how_it_works/step1.png',
    },
    {
      title: 'Technical Interview',
      tracker: 'Technical Interview',
      image: 'https://d2b1cooxpkirg1.cloudfront.net/publicAssets/how_it_works/step2.png',
    },
    {
      title: 'Feedback',
      tracker: 'Feedback',
      image: 'https://d2b1cooxpkirg1.cloudfront.net/publicAssets/how_it_works/step3.png',
    },
  ];

  const [selectedStep, setSelectedStep] = useState(0);

  const handleStepClick = (index) => {
    setSelectedStep(index);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const nextStep = (selectedStep + 1) % steps.length;
      setSelectedStep(nextStep);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [selectedStep, steps]);
  return (
    <section className="section-how-it-works">
      <div id="how-it-works-intersect-indicator" className="iv-how-it-works-container-wrapper px-rp75 px-lg-3 py-r4 py-lg-r7" style={{ animationDuration: '8000ms' }}>
        <div className="iv-how-it-works-1 container">
          <div className="row">
            <div className="col text-center">
              <div className="iv-how-it-works-content-title">Here's how it works</div>
              <a onClick={contact} className="iv-how-it-works-3 section-link light" data-tracker="How it works subtitle (Start free trial) is clicked">
                <span>Start free trial</span>
                <i className="anticon">
                  <svg width="1.3333em" height="1em" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 6H14.5M14.5 6L9.5 1M14.5 6L9.5 11" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </i>
              </a>
            </div>
          </div>
          <div className="iv-how-it-works-4 row gy-4 gy-sm-0">
            <div className="iv-how-it-works-5 col-lg-5 pe-md-r2 pe-xl-r4">
              {steps.map((step, index) => (
                <motion.div
                  key={index}
                  id={`work-selector-${index}`}
                  data-work-index={index}
                  className={`iv-how-it-works-6 ${index === selectedStep ? 'active' : ''}`}
                  data-tracker={`${step.tracker} is clicked`}
                  onClick={() => handleStepClick(index)}
                  whileHover={{ scale: 1.1, boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)' }}
                  whileTap={{ scale: 0.9 }}
                >
                  <motion.div className="iv-how-it-works-7">{index + 1}</motion.div>
                  <motion.p>{step.title}</motion.p>
                  <div className="how-it-works-progress" />
                </motion.div>
              ))}
            </div>
              {/* <div className="work-titles-mobile col-lg-5">
                <div id="work-arrow-left" className="arrow-wrapper">
                  <i className="anticon">
                    <svg viewBox="0 0 17 15" width="1em" fill="currentColor">
                      <path d="M15.5 7.5H1m0 0L7.5 1M1 7.5L7.5 14" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </i>
                </div>
                {steps.map((step, index) => (
                  <div key={index} id={`work-selector-mobile-${index}`} data-work-index={index} className={`iv-how-it-works-6 solution-mobile ${index === 0 ? 'active' : ''}`}>
                    <div className="iv-how-it-works-7">{index + 1}</div>
                    <p>{step.title}</p>
                    <div className="how-it-works-progress" />
                  </div>
                ))}
                <div id="work-arrow-right" className="arrow-wrapper">
                  <i className="anticon">
                    <svg viewBox="0 0 17 16" width="1em" fill="currentColor">
                      <path d="M13.586 7L8.293 1.707A1 1 0 019.707.293l7 7a1 1 0 010 1.414l-7 7a1 1 0 11-1.414-1.414L13.586 9H1a1 1 0 110-2h12.586z" fill="currentColor" fillRule="nonzero" />
                    </svg>
                  </i>
                </div>
              </div> */}

            <div className="iv-how-it-works-8 col-lg-7 pt-r2 pt-lg-0 aos-init aos-animate" data-aos="fade-up" data-aos-offset={-100}>
              {steps.map((step, index) => (
                <motion.img
                  key={index}
                  id={`how-it-works-image-${index}`}
                  className="how-it-works-images"
                  src={step.image}
                  alt={step.title}
                  style={{ display: index === selectedStep ? 'block' : 'none' }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};


export default HireTalentHomePage;



