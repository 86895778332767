import { call, put } from "redux-saga/effects";
import {
  generateOtp,
  verifyEmail,
  login,
  password,
  signUpCall,
  social,
  profileUpdate,
  signUpVerifyy,
  forgetPasswordCall,
  userDetailsCall,
  updateUserPasswordCall,
} from "../../network";
import {
  emailConstants,
  otpConstants,
  loginConstants,
  passwordLoginConstants,
  signUpConstants,
  socialLoginConstants,
  updateProfileConstants,
  signUpVerifyConstants,
  forgetPasswordConstants,
  userDetailsConstants,
  updateUserPasswordConstants,
} from "../../constants";

export function* otpSaga(action) {
  try {
    const response = yield call(generateOtp, action.data);
    yield put({
      type: otpConstants.GENERATE_OTP_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({ type: otpConstants.GENERATE_OTP_FAILURE, error: e.message });
  }
}

export function* verifySaga(action) {
  try {
    const response = yield call(verifyEmail, action.data);
    yield put({
      type: emailConstants.VERIFY_SUCCESS,
      data: response.data,
      error: response.error,
      status: response.status,
      headers: response.headers,
    });
  } catch (e) {
    yield put({
      type: emailConstants.VERIFY_FAILURE,
      status: e.status,
      error: e.message,
      headers: e,
    });
  }
}
export function* loginSaga(action) {
  try {
    saveTokenInLocalStorage(action.data.deviceToken);
    const response = yield call(login, action.data);

    yield put({
      type: loginConstants.LOGIN_SUCCESS,
      data: response.data,
      error: response.error,
      headers: response.headers,
    });
    saveTokenInSessionStorage(response.headers);
  } catch (e) {
    yield put({
      type: loginConstants.LOGIN_FAILURE,
      error: e.message,
      headers: e,
    });
  }
}

function saveTokenInLocalStorage(devicetoken) {
  localStorage.setItem("device-token", JSON.stringify(devicetoken));
}

function saveTokenInSessionStorage(headers) {
  sessionStorage.setItem(
    "x-access-token",
    JSON.stringify(headers["x-access-token"])
  );
  sessionStorage.setItem(
    "x-refresh-token",
    JSON.stringify(headers["x-refresh-token"])
  );
}

export function* passwordLoginSaga(action) {
  try {
    const response = yield call(password, action.data);

    yield put({
      type: passwordLoginConstants.PASSWORD_LOGIN_SUCCESS,
      data: response.data,
      error: response.error,
      status: response.status,
      message: response.message,
      headers: response.headers,
    });
  } catch (e) {
    yield put({
      type: passwordLoginConstants.PASSWORD_LOGIN_FAILURE,
      error: e.message,
      status: e.status,
      headers: e.headers,
    });
  }
}

export function* signUpSaga(action) {
  try {
    const response = yield call(signUpCall, action.data);

    yield put({
      type: signUpConstants.SIGN_UP_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
      message: response.message,
      headers: response.headers,
    });
  } catch (e) {
    console.log("e", e);
    yield put({ type: signUpConstants.SIGN_UP_FAILURE, error: e.message });
  }
}

export function* socialLoginSaga(action) {
  try {
    const response = yield call(social, action.data);

    yield put({
      type: socialLoginConstants.SOCIAL_LOGIN_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: socialLoginConstants.SOCIAL_LOGIN_FAILURE,
      error: e.message,
    });
  }
}

export function* updateProfileSaga(action) {
  try {
    const response = yield call(profileUpdate, action.data);

    yield put({
      type: updateProfileConstants.UPDATE_PROFILE_SUCCESS,
      data: response.data,
      error: response.error,

      status: response.status,
      headers: response.headers,
    });
  } catch (e) {
    yield put({
      type: updateProfileConstants.UPDATE_PROFILE_FAILURE,
      error: e.message,
    });
  }
}

export function* signUpVerifySaga(action) {
  try {
    const response = yield call(signUpVerifyy, action.data);
    yield put({
      type: signUpVerifyConstants.SIGNUP_VERIFY_SUCCESS,
      headers: response.headers,
      data: response.data,
      status: response.status,
      error: response.error,
      message: response.message,
    });
  } catch (e) {
    yield put({
      type: signUpVerifyConstants.SIGNUP_VERIFY_FAILURE,
      error: e.message,
      headers: e,
    });
  }
}

export function* forgetPasswordSaga(action) {
  try {
    const response = yield call(forgetPasswordCall, action.data);

    yield put({
      type: forgetPasswordConstants.FORGET_PASSWORD_SUCCESS,
      data: response.data,
      error: response.error,
      message: response.message,
      headers: response.headers,
    });
  } catch (e) {
    yield put({
      type: forgetPasswordConstants.FORGET_PASSWORD_FAILURE,
      error: e.message,
      headers: e.headers,
    });
  }
}

export function* userDetailsSaga(action) {
  try {
    const response = yield call(userDetailsCall, action.data);

    yield put({
      type: userDetailsConstants.USER_DETAILS_SUCCESS,
      data: response.data,
      error: response.error,
      message: response.message,
      headers: response.headers,
    });
  } catch (e) {
    yield put({
      type: userDetailsConstants.USER_DETAILS_FAILURE,
      error: e.message,
      headers: e.headers,
    });
  }
}
export function* updateUserPasswordSaga(action) {
  try {
    const response = yield call(updateUserPasswordCall, action.data);

    yield put({
      type: updateUserPasswordConstants.UPDATE_USER_PASSWORD_SUCCESS,
      data: response.data,
      error: response.error,
      message: response.message,
      headers: response.headers,
    });
  } catch (e) {
    yield put({
      type: updateUserPasswordConstants.UPDATE_USER_PASSWORD_FAILURE,
      error: e.message,
      headers: e.headers,
    });
  }
}