import React, { useEffect, useState } from "react";
import SelectListComponent from "../../../SelectListComponent";
import SelectMultiInputs from "../../selectMultiInputs";
import {
  languages,
  countries,
  expertise,
  experience,
} from "../../../../assets/data/data";
import ReactSelect from "react-select";
import CreatableSelect from "react-select/creatable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { getUserDetails } from "../../../../redux/actions/login";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { REACT_USER_ID } from "../../../auth/tokenProvider";
import TimezoneSelector from "../../TimeZoneSelector";

const One = ({
  registrationProcessData,
  setRegistrationProcessData,
  errors,
  state,
  setState,
}) => {
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [selectedExperties, setSelectedExperties] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedExperience, setSelectedExperience] = useState([]);
  // length 142
  let language = languages;
  useEffect(() => {
    if (registrationProcessData.language?.length > 0) {
      let l = [];
      registrationProcessData?.language?.map((item, id) => {
        if (item !== selectedLanguage[id]?.value) {
          // let label = language?.filter((val) => {
          //   if (val?.label == item) return val;
          // });
          l.push({ label: item, value: item });
        }
      });
      setSelectedLanguage(l);
    }
    // ---------------- experties data
    if (registrationProcessData.experties?.length > 0) {
      let exp = [];
      registrationProcessData?.experties?.map((item, id) => {
        if (item !== selectedExperties[id]?.value) {
          // let label = expertise?.filter((val) => {
          //   if (val?.value == item) return val;
          // });
          exp.push({ label: item, value: item });
        }
      });
      setSelectedExperties(exp);
    }
    if (registrationProcessData.country !== "") {
      countries?.map((item, id) => {
        if (item.label == registrationProcessData.country) {
          setSelectedCountries([item]);
        }
      });
    }
    if (registrationProcessData.experience !== "") {
      experience?.map((item, id) => {
        if (item.label == registrationProcessData.experience) {
          setSelectedExperience([item]);
        }
      });
    }
  }, []);

  const dispatch = useDispatch();
  const user_id = REACT_USER_ID;
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getUserDetails(user_id));
  }, []);

  const userDetailsReducerData = useSelector((state) => {
    return state.userDetailsReducer;
  });
  const userDetailsArray = userDetailsReducerData?.data || [];
  const userDetails = userDetailsArray[0];

  const isLoading = userDetailsReducerData?.loading;

  const handleLanguageRemove = (language) => {
    const updatedLanguages = selectedLanguage.filter(
      (item) => item.value !== language.value
    );
    setSelectedLanguage(updatedLanguages);
    setRegistrationProcessData((prev) => ({
      ...prev,
      language: updatedLanguages.map((lang) => lang.label),
    }));
  };

  const handleRemove = (title, toRemove, selectedData, setSelectedData) => {
    const updatedData = selectedData.filter((item) => {
      return item.value !== toRemove.value;
    });
    setSelectedData(updatedData);

    setRegistrationProcessData((prev) => ({
      ...prev,
      [title]: updatedData.map((title) => title.label),
    }));
  };

  return (
    <>
      <div className="registrationCard">
        <div className=" leftSide">
          <h3 className=" mb-32 fw-bold">
            Hello, {userDetails?.name} What’s your origin story?
          </h3>
          <p className="related-context">
            Before we jump into details Let us know what country you live in
            what languages you can speak and what is your expertise.
          </p>
        </div>
        <div className="row rightSide">
          <div className="container">
            <div className="form-group">
              <TimezoneSelector setRegistrationProcessData={setRegistrationProcessData} registrationProcessData={registrationProcessData} />
            </div>
          </div>
          <div className="container">
            {/* <div>Which label do you live in?.</div> */}
            <div className="form-group">
              <SelectListComponent
                name="country"
                label="What country you live in?."
                title="Country"
                value={selectedCountries ? selectedCountries : [{}]}
                onChangeFunc={(e) => {
                  let l = [];
                  l?.push(e?.label);
                  setSelectedCountries(e);
                  setRegistrationProcessData((prev) => ({
                    ...prev,
                    country: l[0],
                  }));
                }}
                closeMenuOnSelect={true}
                options={countries}
                className="basic-multi-select text-left"
                placeholder="Ex. India, USA, etc."
                {...errors.country}
              />
            </div>
          </div>

          <div className="container">
            <div className="form-group">
              {selectedLanguage.length > 0 ? (
                <div className="multi-inputs-container">
                  <div className="text">What language(s) do you speak?</div>
                  <div className="multi-inputs">
                    {selectedLanguage.map((language) => (
                      <div className="multi-input-design" key={language.value}>
                        <div key={language.value}>{language.label}</div>
                        <div
                          key={language.value}
                          onClick={() => handleLanguageRemove(language)}
                        >
                          <FontAwesomeIcon
                            className="multi-select-icon"
                            icon={faXmark}
                            style={{ color: "gray" }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className=" multi-inputs-container">
                  <label>What language(s) do you speak?</label>
                </div>
              )}

              <SelectMultiInputs
                options={language}
                isMulti={true}
                title="language"
                // label={"What language(s) do you speak?"}
                onChangeFunc={(e) => {
                  // console.log(e);
                  setSelectedLanguage((prev) => [...prev, e]);
                  setRegistrationProcessData((prev) => ({
                    ...prev,
                    language: [...prev.language, e.label],
                  }));
                }}
                value=""
                name="language"
                isCreatable={true}
                placeholder="Eg. Hindi,English,French"
                className="basic-multi-select text-left "
                classNamePrefix="select"
                inputData={selectedLanguage}
                closeMenuOnSelect={true}
                {...errors.language}
              />
            </div>
          </div>

          <div className="container ">
            <div className="form-group">
              {selectedExperties.length > 0 ? (
                <div className="multi-inputs-container">
                  <div className="text">
                    Which experties would you like to mentor?
                  </div>
                  <div className="multi-inputs">
                    {selectedExperties.map((experties) => (
                      <div className="multi-input-design" key={experties.value}>
                        <div key={experties.value}>{experties.label}</div>
                        <div
                          key={experties.value}
                          onClick={() =>
                            handleRemove(
                              "experties",
                              experties,
                              selectedExperties,
                              setSelectedExperties
                            )
                          }
                        >
                          <FontAwesomeIcon
                            className="multi-select-icon"
                            icon={faXmark}
                            style={{ color: "gray" }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className=" multi-inputs-container">
                  <labe>Which experties would you like to mentor?</labe>
                </div>
              )}
              <SelectMultiInputs
                title="experties"
                // label={"Which experties would you like to mentor?"}
                value=""
                onChangeFunc={(e) => {
                  setSelectedExperties((prev) => [...prev, e]);
                  // console.log('label', e.label);
                  setRegistrationProcessData((prev) => ({
                    ...prev,
                    experties: [...prev.experties, e.label],
                  }));
                }}
                is2d={true}
                placeholder="Not found! Type your own"
                IsMulti
                isCreatable={true}
                name="experties"
                options={expertise}
                closeMenuOnSelect={true}
                className="basic-multi-select text-left"
                classNamePrefix="select"
                inputData={selectedExperties}
                {...errors.experties}
              />
            </div>
          </div>
          <div className="container">
            <div className="form-group ">
              <SelectListComponent
                title={"experience"}
                label={"Level of experience?"}
                value={selectedExperience ? selectedExperience : ""}
                onChangeFunc={(e) => {
                  let l = [];
                  l?.push(e?.value);
                  setSelectedExperience(e);
                  setRegistrationProcessData((prev) => ({
                    ...prev,
                    experience: l[0],
                  }));
                }}
                menuPlacement="top"
                closeMenuOnSelect={true}
                placeholder="Select Level"
                name="experience"
                options={experience}
                className="basic-multi-select text-left"
                classNamePrefix="select"
                {...errors.experience}
              />
            </div>
          </div>
        </div>
      </div>
      {/* </div>
      </div> */}
    </>
  );
};

export default One;
